import React, { useState, useEffect } from "react"
import { useHistory, useParams } from "react-router-dom"
import { view } from "@risingstack/react-easy-state"
import styled from "styled-components"
import AceEditor from "react-ace"
import BraftEditor from "braft-editor"
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons"
import { Row, Col, Button, message } from "antd"

// allowed languages
import "brace/mode/html"
import "brace/mode/css"
import "brace/mode/javascript"
import "brace/mode/ruby"
import "brace/mode/php"
import "brace/mode/json"

// allowed theme
import "brace/theme/monokai"

import { Color, BoxShadow } from "constants/theme"
import Header from "components/common/layouts/Header"
import ContentLoading from "components/common/ContentLoading"
import ContentLoadingError from "components/common/ContentLoadingError"
import EmptyContent from "components/common/EmptyContent"
import CourseContentSider from "components/common/CourseContentSider"
import YoutubeVideoPlayer from "components/common/YoutubePlayer"
import ImageViewer from "components/common/FileViewerImage"
import PdfViewer from "components/common/FileViewerPdf"
import CommentsBox from "components/common/CommentsBox"

import { renderAsHTML } from "utils/react"

import globalStore from "store"

import LecturesService from "services/student/lectures.service"
import UserLecturesService from "services/student/userLectures.service"

const StyledPage = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: ${Color.pageColor};
`

const StyledPageContent = styled.div`
  position: relative;
`

const StyledPageContentCoverBackground = styled.div`
  width: 100%;
  position: absolute;
  overflow: hidden;
  height: 100vh;
  & > div {
    min-width: calc(100vw + 100px);
    height: 400px;
    border-radius: 0px 0px 0px 20px;
    background-image: linear-gradient(20deg, ${Color.brandColor} 7%, #2dbde4 93%);
    position: absolute;
    -webkit-tranform-origin: bottom left;
    tranform-origin: bottom left;
    -webkit-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    transform: rotate(-10deg);
    left: -50px;
    top: -120px;
  }
`

const StyledMainContent = styled.div`
  padding: 20px 100px;
  & > div.container {
    margin-top: 30px;
    & > .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      & > .title {
        color: white;
        z-index: 1;
        font-size: 1.8em;
      }
      & > .action-buttons {
        & > button:nth-of-type(1) {
          // margin-right: 10px;
        }
      }
    }
  }
`
const StyledLectureDetailContainer = styled.div``

const StyledLecturesListContainer = styled.div`
  position: sticky;
  top: 20px;
  min-height: calc(100vh - 40px);
  background: white;
  box-shadow: ${BoxShadow.card.normal};
  border-radius: 7px;
`

const StyledContentCard = styled.div`
  overflow: hidden;
  min-height: 30px;
  background: white;
  box-shadow: ${BoxShadow.card.normal};
  border-radius: 7px;
  margin-bottom: 30px;
  padding: 20px;
  & > .action-button {
    margin-top: 20px;
    text-align: center;
  }
  & .wistia_embed_initialized {
    width: 100% !important;
  }
  & #code-editor {
    width: 100% !important;
    height: auto !important;
    min-height: 250px !important;
    max-height: 500px !important;
  }
`

const StyledLink = styled.div`
  position: relative;
  & > button {
    padding: 0px;
    color: white;
    &:hover {
      color: white;
    }
  }
`

const LecturesShowPage = () => {
  const history = useHistory()
  const { courseId, lectureId } = useParams()
  const pageState = globalStore.ui.student.lectures.show
  const { lecture_comments } = pageState

  const [completeButtonLoading, setCompleteButtonLoading] = useState(false)

  useEffect(() => {
    const fetchPageData = async () => {
      await LecturesService.show({ pageState, courseId, lectureId })

      const { lectureErrors, courseErrors } = pageState

      if (courseErrors && courseErrors.length > 0) {
        const errorMsg = typeof courseErrors[0] === "string" ? courseErrors[0] : "Course not found"
        message.error(errorMsg)
        history.push(`/courses`)
        return
      }

      if (lectureErrors && lectureErrors.length > 0) {
        const errorMsg = typeof lectureErrors[0] === "string" ? lectureErrors[0] : "Lecture not found"
        message.error(errorMsg)
        history.push(`/courses/${courseId}`)
        return
      }
    }

    fetchPageData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lectureId])

  const handleCompleteAndContinue = async () => {
    setCompleteButtonLoading(true)

    await UserLecturesService.complete({ pageState, lectureId })

    setCompleteButtonLoading(false)

    const { lecture, next_lecture } = pageState

    globalStore.ui.student.lectures.show.lecture = lecture

    if (next_lecture) {
      history.push(`/courses/${courseId}/lectures/${next_lecture.id}`)
      return
    }

    history.push(`/courses/${courseId}`)

    return
  }

  const getMainContentComponent = ({ status, lecture, course }) => {
    return (
      <div className="container">
        <div className="header" style={{ opacity: status === "success" ? 1 : 0 }}>
          <div className="title">{lecture && lecture.name}</div>
          <div className="action-buttons">
            <Button type="primary" size="large" onClick={handleCompleteAndContinue} loading={completeButtonLoading}>
              {lecture && lecture.completed ? "Continue" : "Complete and Continue"} <ArrowRightOutlined />
            </Button>
          </div>
        </div>

        <Row gutter={20}>
          <Col xs={{ span: 24 }} lg={{ span: 16 }}>
            <StyledLectureDetailContainer>
              {status === "loading" && <ContentLoading title="Loading lecture's contents..." />}
              {status === "error" && <ContentLoadingError />}
              {status === "success" && renderLectureContents({ lecture })}
            </StyledLectureDetailContainer>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 8 }}>
            <StyledLecturesListContainer>
              <CourseContentSider sections={course && course.sections} />
            </StyledLecturesListContainer>
          </Col>
        </Row>
      </div>
    )
  }

  const renderLectureContents = ({ lecture }) => {
    if (!lecture) return null

    const { contents, comments_allowed } = lecture

    if (!contents || contents.length === 0) {
      return <EmptyContent />
    }

    const lecturesJSX = contents.map((content) => {
      if (content.content_type === "file") {
        const attachedFile = content.attached_file

        console.log("attached file", attachedFile)

        if (!attachedFile || !attachedFile.contentType) return null

        if (attachedFile.contentType.includes("image")) {
          return (
            <StyledContentCard key={content.id}>
              {/* <h2>Note</h2> */}
              <ImageViewer attachedFile={attachedFile} />
            </StyledContentCard>
          )
        }

        if (attachedFile.contentType.includes("pdf")) {
          return (
            <StyledContentCard key={content.id}>
              {/* <h2>Note</h2> */}
              <PdfViewer attachedFile={attachedFile} />
            </StyledContentCard>
          )
        }
      }

      if (!content.details) return null

      if (content.content_type === "video" && content.details.wistia_response) {
        return (
          <StyledContentCard key={content.id}>
            {/* <h2>Video</h2> */}
            {renderAsHTML(content.details.wistia_response.html)}
          </StyledContentCard>
        )
      }
      if (content.content_type === "video" && content.details.youtube_url) {
        return (
          <StyledContentCard key={content.id}>
            {/* <h2>Video</h2> */}
            <YoutubeVideoPlayer url={content.details.youtube_url} />
          </StyledContentCard>
        )
      }
      if (content.content_type === "note") {
        return (
          <StyledContentCard key={content.id}>
            {/* <h2>Note</h2> */}
            <BraftEditor
              controls={[]}
              readOnly={true}
              language="en"
              value={BraftEditor.createEditorState(content.details.html)}
              className="braft-editor-main"
            />
          </StyledContentCard>
        )
      }
      if (content.content_type === "code") {
        return (
          <StyledContentCard key={content.id}>
            {/* <h2>Code</h2> */}
            <AceEditor
              mode={content.details.language}
              readOnly={true}
              theme="monokai"
              name="code-editor"
              value={content.details.code}
            />
          </StyledContentCard>
        )
      }
      return null
    })

    return (
      <React.Fragment>
        {lecturesJSX}
        {comments_allowed && (
          <CommentsBox
            lectureId={lecture.id}
            pageState={pageState}
            lectureComments={lecture_comments}
            lectureCommentsMeta={lecture_comments.meta}
          />
        )}
      </React.Fragment>
    )
  }

  const renderMainContent = () => {
    const { lecture, course, lectureErrors, API_LECTURES_SHOW_STATUS } = pageState

    if (!API_LECTURES_SHOW_STATUS) {
      return getMainContentComponent({ status: "loading", lecture, course })
    }
    if (API_LECTURES_SHOW_STATUS === "pending") {
      return getMainContentComponent({ status: "loading", lecture, course })
    }
    if (API_LECTURES_SHOW_STATUS === "rejected") {
      return getMainContentComponent({ status: "error" })
    }

    if (lectureErrors && lectureErrors.length > 0) {
      const errorMessage = typeof lectureErrors[0] === "string" ? lectureErrors[0] : "Oops!! something went wrong"

      message.error(errorMessage)
      history.push(`/courses/${courseId}`)
      return

      // return this.getMainContentComponent({ status: "error" })
    }

    return getMainContentComponent({ status: "success", lecture, course })
  }

  return (
    <StyledPage>
      <Header />
      <StyledPageContent>
        <StyledPageContentCoverBackground>
          <div></div>
        </StyledPageContentCoverBackground>
        <StyledMainContent>
          <Row>
            <StyledLink>
              <Button type="link" onClick={() => history.push(`/courses/${courseId}`)}>
                <ArrowLeftOutlined /> Go to Course Page
              </Button>
            </StyledLink>
          </Row>
          {renderMainContent()}
        </StyledMainContent>
      </StyledPageContent>
    </StyledPage>
  )
}

export default view(LecturesShowPage)
