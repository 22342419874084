import React from 'react'
import { view } from "@risingstack/react-easy-state"


class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    //Send error on slack
    console.log("Error Caught: Error:", error, "info:", info)
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="page-error not-found-page">
          <h1>Oops!</h1>
          <h3>Some error occured please try refreshing page, if this continues please mail us support@coursemango.com</h3>

          <p>Go back to <a href="/"> Dashboard </a></p>
        </div>
      )
    }

    return this.props.children;
  }
}

export default view(ErrorBoundary)
