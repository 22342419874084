import React, {lazy} from "react"
import { useHistory } from "react-router-dom"
import { view } from "@risingstack/react-easy-state"
import styled from "styled-components"
import { CheckCircleFilled } from "@ant-design/icons"
import { Input, Button, Form } from "antd"

import { BoxShadow, Color } from "constants/theme"

import FormIcon from "components/common/FormIcon"

let SocialLogin
if (process.env.REACT_APP_FIREBASE_API_KEY) {
  SocialLogin = lazy(() => import("components/common/SocialLogin/Index"))
}

const StyledFormWrapper = styled.div`
  position: relative;
  max-width: 400px;
  width: 100%;
  max-height: 400px;
  margin: auto;
  text-align: center;
  & > h1 {
    margin: 0px 0px 20px 0px;
    text-transform: uppercase;
  }
`

const StyledForm = styled.div`
  width: 100%;
  min-height: 200px;
  background: white;
  box-shadow: ${BoxShadow.card.normal};
  border-radius: 7px;
  & > form {
    padding: 20px 40px 10px 40px;
    border: 0px;
  }
`

const StyledFormHeader = styled.div`
  padding: 20px 30px;
  padding-bottom: 0px;
  & > .anticon {
    font-size: 3rem;
    color: ${Color.brandColor};
  }
  & > h1 {
    margin-top: 10px;
    margin-bottom: 0px;
    text-transform: uppercase;
  }
`

// const StyledSocialLoginButtons = styled.div`
//   position: relative;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   margin-top: 50px;
//   &:before {
//     content: "OR";
//     position: absolute;
//     width: 32px;
//     height: 32px;
//     top: -36px;
//     background: #eaeaea;
//     color: #9012fe6b;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     border-radius: 50%;
//     font-weight: bold;
//   }
//   & > div {
//     width: 100%;
//     height: 32px;
//     padding: 4px 15px;
//     font-size: 14px;
//     border-radius: 2px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     margin-top: 10px;
//     color: white;
//     transition: all 0.5s;
//     cursor: pointer;
//   }
//   & > .facebook-btn {
//     background-color: #4267b2;
//     &:hover {
//       background-color: #304e8a;
//     }
//   }
//   & > .google-btn {
//     background-color: #db4437;
//     &:hover {
//       background-color: #a53228;
//     }
//   }
// `

const LoginForm = ({ title = "Login", subTitle, submitting, heading, locationState, onSubmit }) => {
  const history = useHistory()
  const labelLogin = submitting ? " Logging in..." : "Login"

  const handleRegisterButtonClick = () => {
    history.push({
      pathname: "/register",
      state: locationState,
    })
  }

  const handleForgotPasswordButtonClick = () => {
    history.push({
      pathname: "/forgot-password",
      state: locationState,
    })
  }

  return (
    <StyledFormWrapper>
      {heading && (
        <React.Fragment>
          <h1>{title}</h1>
          <p>{subTitle}</p>
        </React.Fragment>
      )}
      <StyledForm>
        <StyledFormHeader>
          <CheckCircleFilled />
          <h1>Login</h1>
        </StyledFormHeader>
        <Form onFinish={onSubmit} className="login-form form-card">
          <Form.Item name="email" rules={[{ required: true, message: "Please enter email!" }]}>
            <Input prefix={<FormIcon type="mail" />} placeholder="Email" autoComplete="username" />
          </Form.Item>
          <Form.Item name="password" rules={[{ required: true, message: "Please enter password!" }]}>
            <Input.Password
              type="password"
              autoComplete="current-password"
              prefix={<FormIcon type="lock" />}
              placeholder="Password"
            />
          </Form.Item>
          <Button type="primary" block htmlType="submit" className="login-button" loading={submitting}>
            {labelLogin}
          </Button>
          {process.env.REACT_APP_FIREBASE_API_KEY ? <SocialLogin /> : null}

          {/* <StyledSocialLoginButtons>
            <div className="facebook-btn" onClick={() => onSocialLogin("facebook")}>
              Login with Facebook
            </div>
            <div className="google-btn" onClick={() => onSocialLogin("google")}>
              Login with Google
            </div>
          </StyledSocialLoginButtons> */}
          <p style={{ margin: "10px 0px" }}>
            <Button type="link" style={{ padding: 0 }} onClick={handleForgotPasswordButtonClick}>
              Forgot Password
            </Button>
          </p>

          <p className="switch-auth-link" style={{ marginBottom: 0 }}>
            Don't have an account?{" "}
            <Button type="link" style={{ padding: 0 }} onClick={handleRegisterButtonClick}>
              Register
            </Button>
          </p>
        </Form>
      </StyledForm>
    </StyledFormWrapper>
  )
}

export default view(LoginForm)
