import React from "react"
import styled from "styled-components"
import { view } from "@risingstack/react-easy-state"
import { useHistory } from "react-router-dom"
import { EditOutlined, BankOutlined } from "@ant-design/icons"
import { Tooltip, Button } from "antd"

import { Height, BoxShadow } from "constants/theme"
import * as AuthHelper from "helpers/auth.helper"

import globalStore from "store"
// import SchoolsService from "services/admin/schools.service"

import StyledCardBackground from "components/common/styled-components/StyledCardBackground"

const StyledCard = styled.div`
  position: relative;
  width: 100%;
  background-image: url(${(props) => props.url || "https://source.unsplash.com/random"});
  background-size: cover;
  height: ${Height.schoolCard}px;
  box-shadow: ${BoxShadow.card.normal};
  border-radius: 5px;
  overflow: hidden;
  transition: all 0.5s;
  &:hover {
    cursor: pointer;
    box-shadow: ${BoxShadow.card.hover};
  }
  & > div.school-details {
    position: absolute;
    top: 0px;
    width: 100%;
    height: inherit;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: linear-gradient(0deg, #00000094, transparent);
  }
`

const StyledSchoolName = styled.div`
  font-size: 1rem;
  color: white;
  background: #00000066;
  padding: 5px;
  border-radius: 4px;
  & > h3 {
    color: white;
    margin: 0px;
  }
  & > p {
    margin: 0px;
  }
`

const StyledActionButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 1.5em;
  & > div {
    & > i {
      color: white;
    }
  }
`

const StyledButtonGroup = styled.div`
  margin-top: 10px;
  & > .ant-btn-group {
    display: flex;
    width: 100%;
    & > button:nth-of-type(1) {
      flex: auto;
    }
  }
`

const SchoolCard = ({ school, owned, openUploadModal }) => {
  const history = useHistory()
  const pageState = globalStore.ui.users.profile
  // const [removeButtonLoading, setRemoveButtonLoading] = useState(false)

  if (pageState.school && pageState.school.id === school.id) {
    school.display_picture_url = pageState.school.display_picture_url
  }

  // const removeDisplayPicture = async () => {
  //   setRemoveButtonLoading(true)
  //   await SchoolsService.removeDisplayPicture({ pageState, schoolId: school.id })
  //   setRemoveButtonLoading(false)

  //   if (school.display_picture_url) {
  //     message.error("Failed to remove display picture")
  //     return
  //   }

  //   message.success("Display picture removed successfully")
  // }

  const handleEditButtonClick = () => {
    history.push("/admin/edit-school")
  }

  const getUrl = (school, owned) => {
    const protocol = process.env.NODE_ENV === "development" ? "http" : "https"
    const host = school.domain || `${school.subdomain}.${process.env.REACT_APP_FRONTEND_HOST}`
    const adminPart = owned ? "/admin/courses" : ""
    const url = `${protocol}://${host}${adminPart}?token=${AuthHelper.getToken()}`
    return url
  }

  return (
    <StyledCard>
      <StyledCardBackground url={school.display_picture_url}>
        {!school.display_picture_url && <BankOutlined />}
      </StyledCardBackground>
      <div className="school-details">
        <StyledActionButton>
          <div>{/* <Icon type="heart" /> */}</div>
          <div>
            {/* {owned && school.display_picture_url && (
              <Tooltip title="Delete Display Picture">
                <Button danger shape="circle" disabled={removeButtonLoading} onClick={removeDisplayPicture}>
                  {removeButtonLoading ? <LoadingOutlined /> : <DeleteOutlined />}
                </Button>
              </Tooltip>
            )} */}
            {
              <Tooltip title="Edit School">
                <Button shape="circle" onClick={handleEditButtonClick}>
                  <EditOutlined />
                </Button>
              </Tooltip>
            }
          </div>
        </StyledActionButton>
        <div>
          <Tooltip placement="top" title={school.name} arrowPointAtCenter>
            <StyledSchoolName>
              <h3>{school.name}</h3>
              <p>{school.subtitle}</p>
            </StyledSchoolName>
          </Tooltip>
          <StyledButtonGroup>
            <Button.Group size="large">
              <Button type="primary" onClick={() => (window.location = getUrl(school, owned))}>
                Visit School
              </Button>
              {/* {owned && (
                <Button onClick={() => openUploadModal({ schoolId: school.id })}>
                  <UploadOutlined />
                </Button>
              )} */}
            </Button.Group>
          </StyledButtonGroup>
        </div>
      </div>
    </StyledCard>
  )
}

export default view(SchoolCard)
