import React, { useEffect } from "react"
import { view } from "@risingstack/react-easy-state"
import { useHistory } from "react-router-dom"
import styled from "styled-components"

import {
  LeftOutlined,
  RightOutlined,
  ReadOutlined,
  // SnippetsOutlined,
  PoweroffOutlined,
  UserOutlined,
  CreditCardOutlined,
  // HomeOutlined,
  QuestionOutlined,
  BankOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons"
import { Avatar, Button } from "antd"

import ContentLoading from "components/common/ContentLoading"

import globalStore from "store"
import SchoolsService from "services/admin/schools.service"

import * as URLHelper from "helpers/url.helper"

import { Color, BoxShadow } from "constants/theme"

const StyledPageSider = styled.div`
  position: fixed;
  width: 280px;
  left: 20px;
  top: 20px;
  min-height: calc(100vh - 40px);
  background: white;
  box-shadow: ${BoxShadow.card.normal};
  border-radius: 7px;
`

const StyledSiderContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  position: absolute;
  width: 100%;
  font-size: 1.5em;
  font-weight: bold;
  color: ${Color.brandColor};
  & > .school-name {
    padding: 20px 10px;
    text-align: center;
  }
  & > .ant-avatar {
    margin: 20px 0px 20px 0px;
    background: ${Color.brandColor};
  }
  & > h2,
  & > p {
    font-size: 0.8em;
    color: black;
    font-weight: normal;
  }
`
const StyledSiderMenu = styled.ul`
  position: relative;
  margin: ${(props) => (props.submenu ? "0px" : "30px")} 0px 0px 0px;
  padding: 0px;
  list-style-type: none;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  & > li {
    margin-bottom: -1px;
    border: 1px solid whitesmoke;
    & > button {
      width: 100%;
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: black;
      text-align: left;
      & > span > span.anticon {
        // margin-right: 10px;
      }
      & .submenu-header {
        font-weight: bold;
      }
      & .menuitem-title {
        margin-left: 10px;
      }
    }
  }
  & > li.item-selected {
    & > button {
      color: ${Color.brandColor};
    }
  }
`

const StyledSubMenuContainer = styled.div`
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  background: white;
  transition: all 0.5s;
  right: ${(props) => (props.show ? "0px" : "-280px")};
`

const StyledEditSchoolLink = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px;
`

const StyledAvatar = styled.div`
  height: 100px;
`

const menuItems = [
  {
    dataIndex: "courses",
    icon: <ReadOutlined />,
    title: "Courses",
    path: "/admin/courses",
  },
  {
    dataIndex: "webinars",
    icon: <VideoCameraOutlined />,
    title: "Webinars",
    path: `/admin/webinars`,
  },
  // {
  //   dataIndex: "landing-pages",
  //   icon: <SnippetsOutlined />,
  //   title: "Landing Pages",
  //   path: "/admin/landing-pages",
  // },
  {
    dataIndex: "profile",
    icon: <UserOutlined />,
    title: "Profile",
    path: "/profile",
  },
  {
    dataIndex: "plan",
    icon: <CreditCardOutlined />,
    title: "Plan",
    path: "/admin/settings/plan",
  },
  // {
  //   dataIndex: "coursemango",
  //   icon: <HomeOutlined />,
  //   title: "CourseMango",
  //   path: "/admin?to=home",
  // },
  {
    dataIndex: "bank",
    icon: <BankOutlined />,
    title: "Bank Details",
    path: "/admin/bank-details",
  },
  {
    dataIndex: "help",
    icon: <QuestionOutlined />,
    title: "Help",
    path: "/admin/settings/help",
  },
]

const courseSubmenu = (courseId) => [
  {
    title: "Curriculum",
    path: `/admin/courses/${courseId}/curriculum`,
  },
  {
    title: "Information",
    path: `/admin/courses/${courseId}/information`,
  },
]

const Sider = () => {
  const history = useHistory()
  const siderState = globalStore.currentSchool

  // Fetch school data if not already fetched
  useEffect(() => {
    const siderState = globalStore.currentSchool

    // Call the API only once
    if (!!siderState.API_SCHOOLS_ME_STATUS) return

    const fetchData = async () => {
      await SchoolsService.me({ pageState: siderState })
    }

    fetchData()
  }, [siderState.school, siderState.API_SCHOOLS_ME_STATUS])

  const courseId = URLHelper.getAdminCourseId()

  useEffect(() => {
    if (!courseId) return

    siderState.selectedDataIndex = "courses"
    siderState.selectedItemChildren = courseSubmenu(courseId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseId])

  useEffect(() => {
    const { pathname } = history.location

    const menuItem = menuItems.find((mi) => mi.path === pathname)

    if (!menuItem) return

    siderState.selectedDataIndex = menuItem.dataIndex

    if (menuItem.dataIndex !== "courses") {
      siderState.selectedItemChildren = menuItem.children || []
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const showItemSubMenuOrRedirect = (e, menuItem) => {
    if (!menuItem) return

    siderState.selectedDataIndex = menuItem.dataIndex

    if (menuItem.children && menuItem.children.length > 0) {
      siderState.selectedItemChildren = menuItem.children
      return
    }

    // FIXME: Check if this works for other (non-courses) submenu
    siderState.selectedItemChildren = []
    history.push(menuItem.path)
  }

  const renderSchoolAvatar = () => {
    return (
      <StyledAvatar>
        <Avatar shape="square" size={80} icon={school.display_picture_url || <BankOutlined />} />
      </StyledAvatar>
    )
  }

  const { school, API_SCHOOLS_ME_STATUS, selectedDataIndex, selectedItemChildren } = siderState

  const selectedMenuItem = menuItems.find((mi) => mi.dataIndex === selectedDataIndex)

  const renderSider = () => {
    const loading = API_SCHOOLS_ME_STATUS !== "fulfilled" || !school

    if (loading) return <ContentLoading />

    return (
      <StyledSiderContent>
        <div className="school-name">{school.name}</div>
        {renderSchoolAvatar()}
        <StyledSiderMenu>
          {menuItems.map((mi, index) => {
            return (
              <li
                key={mi.dataIndex}
                id={mi.dataIndex}
                className={history.location.pathname.startsWith(mi.path) ? "item-selected" : ""}
              >
                <Button type="link" onClick={(e) => showItemSubMenuOrRedirect(e, mi)}>
                  <span>
                    {mi.icon} <span className="menuitem-title">{mi.title}</span>
                  </span>
                  <span>
                    {selectedDataIndex === mi.dataIndex && selectedItemChildren.length > 0 && <RightOutlined />}
                  </span>
                </Button>
              </li>
            )
          })}

          <li key="logout">
            <Button type="link" onClick={() => history.push("/logout")}>
              <span>
                <PoweroffOutlined /> <span className="menuitem-title">Logout</span>
              </span>
            </Button>
          </li>

          <StyledSubMenuContainer show={selectedItemChildren.length > 0}>
            {selectedItemChildren.length > 0 && (
              <StyledSiderMenu submenu={true}>
                <li key="submenu-parent">
                  <Button
                    type="link"
                    onClick={() => {
                      siderState.selectedItemChildren = []
                    }}
                  >
                    <span>
                      <LeftOutlined />{" "}
                      <span className="submenu-header menuitem-title">
                        {selectedMenuItem && selectedMenuItem.title}
                      </span>
                    </span>
                  </Button>
                </li>
                {selectedItemChildren.map((smi, i) => (
                  <li key={`submenu-${i}`}>
                    <Button type="link" style={{ marginLeft: 20 }} onClick={(e) => showItemSubMenuOrRedirect(e, smi)}>
                      <span>
                        {smi.icon} <span className="menuitem-title">{smi.title}</span>
                      </span>
                    </Button>
                    {smi.children && <RightOutlined />}
                  </li>
                ))}
              </StyledSiderMenu>
            )}
          </StyledSubMenuContainer>
          <StyledEditSchoolLink>
            <Button size="large" onClick={() => history.push("/admin/edit-school")} block>
              Edit School
            </Button>
          </StyledEditSchoolLink>
        </StyledSiderMenu>
      </StyledSiderContent>
    )
  }

  return <StyledPageSider>{renderSider()}</StyledPageSider>
}

export default view(Sider)
