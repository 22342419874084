import styled from "styled-components"

import { Color } from "constants/theme"

export const GradientText = styled.span`
  font-weight: bold;
  letter-spacing: 1px;
  background: linear-gradient(45deg, ${Color.brandColor}, ${Color.secondaryColor});
  -webkit-background-clip: text;
  color: transparent;
`

export const CustomButton = styled.button`
  padding: 10px 30px;
  border: 0px;
  border-radius: 25px;
  cursor: pointer;
  font-weight: bold;
  outline: none;
  letter-spacing: 1px;
  box-shadow: ${(props) => (props.primary ? "5px 5px 20px 0px #7637fa2e" : "none")};
  background: ${(props) =>
    props.primary ? `linear-gradient(45deg, ${Color.brandColor}, ${Color.secondaryColor})` : "none"};
  color: ${(props) => (props.primary ? "white" : props.withIcon ? `${Color.secondaryColor}` : "black")};

  & > .anticon {
    transform: scale(1.8);
    margin-right: 10px;
  }
`

export const EmailText = styled.span`
  font-weight: bold;
`

export const StyledHomePage = styled.div`
  height: inherit;
  background: white;
  flex: 1;
  & .gradient-row {
    background: linear-gradient(to right, #8029fb, #2caee8);
    padding: 50px;
    display: flex;
    align-items: center;
    overflow: hidden;
  }
  & .gradient-row.right {
    border-radius: 80px 0px 0px 80px;
    &:before {
      content: "";
      position: absolute;
      width: 500px;
      height: 500px;
      background: radial-gradient(#8128fc, #2cabe7);
      border-radius: 50%;
      left: -250px;
      bottom: -250px;
    }
  }
  & .gradient-row.left {
    border-radius: 0px 80px 80px 0px;
    &:before {
      content: "";
      position: absolute;
      width: 500px;
      height: 500px;
      background: radial-gradient(#8128fc, #2cabe7);
      border-radius: 50%;
      right: -250px;
      top: -250px;
    }
  }
  & nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    align-items: center;
    & > .logo {
      padding: 5px 20px;
      font-size: 1.5em;
      font-weight: bold;
      letter-spacing: 1px;
      background: linear-gradient(45deg, ${Color.brandColor}, ${Color.secondaryColor});
      -webkit-background-clip: text;
      color: transparent;
    }
    & > .links {
      & > ul {
        list-style-type: none;
        display: flex;
        margin: 0px;
      }
    }
  }
  & .hero {
    &:before {
      content: "";
      position: absolute;
      width: 500px;
      height: 500px;
      background: radial-gradient(#b0e6fa, white);
      border-radius: 50%;
      left: -250px;
      top: 80%;
    }
    & .left-container {
      padding-left: 150px;
      padding-top: 100px;
      & > h1 {
        font-weight: bold;
        // opacity: 0;
      }
      & > h3 {
        margin: 20px 0px 50px 0px;
        // opacity: 0;
      }
    }
    & .right-container {
      padding-right: 50px;
      padding-top: 50px;
      & > .image-box {
        overflow: hidden;
        & > img {
          width: 100%;
        }
      }
    }
  }

  & .section-2 {
    padding: 80px 0px;
    & .left-container {
      padding-left: 50px;
      & > .image-box {
        overflow: hidden;
        width: 80%;
        margin: auto;
        & > img {
          width: 100%;
        }
      }
    }
    & .right-container {
      padding: 0px 80px;
      & > h1 {
        font-weight: bold;
      }
      & > h3 {
        margin: 20px 0px 50px 0px;
      }
      & > .cta-buttons {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }
  }
  & .section-3 {
    padding: 30px 0px;
    & > h1 {
      font-size: 2.5em;
      text-align: center;
      margin-bottom: 110px;
    }
    & .left-container {
      position: relative;
      padding-left: 50px;
      & > .image-box {
        overflow: hidden;
        width: 80%;
        margin: auto;
        & > img {
          width: 100%;
        }
      }
    }
    & .right-container {
      position: relative;
      padding: 0px 80px;
      & > h1 {
        font-weight: bold;
      }
      & > h3 {
        margin: 20px 0px 50px 0px;
      }
      & > .cta-buttons {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }
  }

  & .section-4 {
    padding: 80px 0px;
    & .left-container {
      position: relative;
      padding: 0px 80px;
      & > h1 {
        font-weight: bold;
      }
      & > h3 {
        margin: 20px 0px 50px 0px;
      }
      & > .cta-buttons {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }
    & .right-container {
      position: relative;
      padding-right: 50px;
      & > .image-box {
        overflow: hidden;
        width: 80%;
        margin: auto;
        & > img {
          width: 100%;
        }
      }
    }
  }
  & .section-5 {
    padding: 30px 0px;
    & > h1 {
      font-size: 2.5em;
      text-align: center;
      margin-bottom: 110px;
    }
    & .left-container {
      position: relative;
      padding-left: 50px;
      & > .image-box {
        overflow: hidden;
        width: 80%;
        margin: auto;
        & > img {
          width: 100%;
        }
      }
    }
    & .right-container {
      position: relative;
      padding: 0px 80px;
      & > h1 {
        font-weight: bold;
      }
      & > h3 {
        margin: 20px 0px 50px 0px;
      }
      & > .cta-buttons {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }
  }
  & .section-6 {
    padding: 50px 0px;
    & > h1 {
      font-size: 2.5em;
      text-align: center;
      margin-bottom: 50px;
    }
    & .frequency-switch {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    & .features {
      text-align: center;
      margin: 30px 0px;
      & > div {
        margin-bottom: 10px;
      }
    }
  }
  & .section-7 {
    padding: 50px 0px;
    margin-top: 50px;
    background: radial-gradient(circle at center, ${Color.brandColor}, ${Color.secondaryColor});
    min-height: 400px;
    & > h1 {
      font-size: 2.5em;
      text-align: center;
      margin-bottom: 50px;
      color: white;
    }
  }
  & .section-8 {
    padding: 50px 0px;
    min-height: 400px;
    & > h1 {
      font-size: 2em;
      text-align: center;
      font-weight: bold;
      margin: 20px 0px;
    }
    & > h3 {
      text-align: center;
      color: #afacac;
    }
    & > .faq-box {
      max-width: 800px;
      margin: 40px auto;
      padding: 50px 50px 20px 50px;
      background: #e7f7fe;
      border-radius: 7px;
      & > label {
        display: block;
        padding: 20px;
        background: white;
        box-shadow: 0px 5px 20px 0px #7637fa12;
        border-radius: 7px;
        margin-bottom: 30px;
        transition: all 0.5s;
        & > input {
          opacity: 0;
          position: absolute;
        }
        & > input:checked + h3 + div {
          display: block;
        }
        & > input:checked + h3 span.anticon-minus-circle {
          display: block;
        }
        & > input:checked + h3 span.anticon-plus-circle {
          display: none;
        }
        & h3 {
          font-weight: bold;
          margin: 0px;
          display: flex;
          justify-content: space-between;
          & .anticon-plus-circle {
            display: block;
          }
          & .anticon-minus-circle {
            display: none;
          }
        }
        & > div {
          display: none;
          margin: 10px 0px 0px 0px;
          font-size: 1.2em;
          & > p:last-child {
            margin: 0px;
          }
        }
      }
    }
  }

  & > .section-9 {
    padding: 50px 0px;
    background: ;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    background: linear-gradient(45deg, ${Color.brandColor}, ${Color.secondaryColor});
    &:before {
      content: "";
      position: absolute;
      width: 500px;
      height: 500px;
      background: radial-gradient(#8128fc, #2cabe7);
      border-radius: 50%;
      left: -250px;
      bottom: -250px;
    }
    & > h3 {
      position: relative;
      color: white;
      margin-bottom: 30px;
    }
    & > button {
      position: relative;
    }
  }
  & > footer {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    border-bottom-width: 3px;
    border-bottom-style: solid;
    border-bottom-color: #e7f7fe;
  }
`
export const StyledPricingCards = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 50px;
  & > div {
    position: relative;
    width: 280px;
    min-height: 450px;
    background: #e7f7fe;
    border-radius: 7px;
    box-shadow: 0px 5px 20px 0px #00000014;
    margin: 0px 40px;
    transition: all 0.5s;
    &:before {
      content: "";
      position: absolute;
      width: calc(100% + 8px);
      height: calc(100% + 8px);
      background: linear-gradient(45deg, ${Color.brandColor}, ${Color.secondaryColor});
      padding: 5px;
      left: -4px;
      border-radius: 5px;
      transition: background 0.8s;
    }
    &:hover {
      box-shadow: 0px 20px 20px 0px #00000014;
    }
    &:hover:before {
      background: linear-gradient(45deg, ${Color.secondaryColor}, ${Color.brandColor});
    }
    & > div {
      position: absolute;
      width: 100%;
      height: 100%;
      background: #ffffff;
      top: 4px;
      border-radius: 3px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding: 20px;
      & > .name {
        margin-top: 30px;
        margin-bottom: 5px;
      }
      & > .frequency {
        color: gray;
        text-transform: capitalize;
      }
      & .price {
        font-size: 2em;
        font-weight: bold;
        margin: 30px 0px;
      }
    }
  }
`

export const StyledTestimonialCards = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 50px;
  & > div {
    padding: 15px 15px 15px 35px;
    width: 280px;
    min-height: 280px;
    background: #0000002b;
    border-radius: 7px;
    box-shadow: 0px 5px 20px 0px #7d2dfb14;
    margin: 0px 40px;
    color: white;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    & > p {
      position: relative;
      font-size: 1.1em;
      &:before {
        content: "“";
        position: absolute;
        font-size: 50px;
        top: -20px;
        left: -25px;
      }
    }
    & > div.client {
      width: 100%;
      & > .name {
        color: white;
        margin: 0px;
      }
      & > .designation {
        color: white;
      }
    }
  }
`

export const InvitationButton = styled.button`
  padding: 10px 30px;
  border: 0px;
  border-radius: 25px;
  cursor: pointer;
  font-weight: bold;
  outline: none;
  letter-spacing: 1px;
  box-shadow: ${(props) => (props.primary ? "5px 5px 20px 0px #7637fa2e" : "none")};
`
