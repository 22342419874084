import { API, generic } from "api"

export default class LandingPagesService {
  static show = async ({ pageState, landingPageId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_LANDING_PAGES_SHOW_STATUS",
      stateDataKey: "landing_page",
      stateErrorKey: "landing_pageErrors",
      apiEndpoint: API.leads.landing_pages.show,
      apiUrlReplacements: { landingPageId },
      apiData: {},
      errorMessage: "Landing Page could not be fetched",
    })
  }
}
