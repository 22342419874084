import React, { useEffect } from "react"
import { view } from "@risingstack/react-easy-state"
import styled from "styled-components"
import { useHistory } from "react-router-dom"
import { BankOutlined, PlusCircleOutlined, PoweroffOutlined, UserOutlined } from "@ant-design/icons"
import { Button, Avatar } from "antd"

import { Color, BoxShadow } from "constants/theme"

import ContentLoading from "components/common/ContentLoading"
// import ContentLoadingError from "components/common/ContentLoadingError"
import CreateSchoolForm from "components/pages/users/profile/CreateSchoolForm"

import globalStore from "store"

import UsersService from "services/users/users.service"

import * as URLHelper from "helpers/url.helper"

const StyledPage = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: ${Color.pageColor};
`

const StyledPageContent = styled.div`
  position: relative;
`

const StyledPageContentCoverBackground = styled.div`
  width: 100%;
  position: absolute;
  overflow: hidden;
  height: 100vh;
  & > div {
    min-width: calc(100vw + 100px);
    height: 400px;
    border-radius: 0px 0px 0px 20px;
    background-image: linear-gradient(20deg, ${Color.brandColor} 7%, #2dbde4 93%);
    position: absolute;
    -webkit-tranform-origin: bottom left;
    tranform-origin: bottom left;
    -webkit-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    transform: rotate(-10deg);
    left: -50px;
    top: -120px;
  }
`

const StyledPageSider = styled.div`
  position: fixed;
  width: 280px;
  left: 20px;
  top: 20px;
  min-height: calc(100vh - 40px);
  background: white;
  box-shadow: ${BoxShadow.card.normal};
  border-radius: 7px;
`

const StyledMainContent = styled.div`
  position: relative;
  width: calc(100vw - 380px); //Total padding(20*3) + Sider width(280)
  left: 360px;
  top: 20px;
  & > div.container {
    width: 100%;
    position: absolute;
    top: 60px;
    min-height: calc(100vh - 40px);
    border-radius: 7px;
  }
`

const StyledSiderContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  position: absolute;
  width: 100%;
  font-size: 1.5em;
  font-weight: bold;
  color: ${Color.brandColor};
  & > .logo {
    padding: 20px 0px;
  }
  & > .ant-avatar {
    margin: 20px 0px 20px 0px;
  }
  & > h2,
  & > p {
    font-size: 0.8em;
    color: black;
    font-weight: normal;
  }
`
const StyledSiderMenu = styled.ul`
  margin: 30px 0px 0px 0px;
  padding: 0px;
  list-style-type: none;
  width: 100%;
  & > li {
    margin-bottom: -1px;
    border: 1px solid whitesmoke;
    & > button {
      height: 40px;
      color: black;
      text-align: left;
    }
  }
`

const StyledEditProfileLink = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px;
`

const CreateSchoolPage = () => {
  const currentUser = globalStore.currentUser
  const history = useHistory()

  const menuItems = [
    {
      label: "My Schools",
      path: "/profile",
      icon: <BankOutlined />,
    },
    {
      label: "Create New School",
      path: "/profile/schools/new",
      icon: <PlusCircleOutlined />,
    },
    {
      label: "Logout",
      onClick: () => history.push("/logout"),
      icon: <PoweroffOutlined />,
    },
  ]

  useEffect(() => {
    // Call the API only once
    if (!!currentUser.API_USERS_ME_STATUS) return

    const fetchData = async () => {
      await UsersService.me({ pageState: currentUser })
    }

    fetchData()
  }, [currentUser])

  const renderSider = () => {
    const { user } = currentUser

    if (!user) return <ContentLoading />

    const menuItemOnClick = (menuItem) => {
      if (menuItem.onClick) return menuItem.onClick
      if (menuItem.path) return () => history.push(`${menuItem.path}`)
    }

    const renderAvatar = () => {
      if (user.display_picture_url) {
        return <Avatar shape="square" size={80} src={user.display_picture_url} />
      }
      return <Avatar shape="square" size={80} icon={<UserOutlined />} />
    }

    return (
      <StyledSiderContent>
        <div className="logo">CourseMango</div>
        {renderAvatar()}
        <h2>{user.name}</h2>
        <p>{user.email}</p>
        <StyledSiderMenu>
          {menuItems.map((mi, index) => (
            <li key={index}>
              <Button type="link" onClick={menuItemOnClick(mi)}>
                {mi.icon} {mi.label}
              </Button>
            </li>
          ))}
        </StyledSiderMenu>
        <StyledEditProfileLink>
          <Button size="large" onClick={() => history.push("/profile/edit")} block>
            Edit Profile
          </Button>
        </StyledEditProfileLink>
      </StyledSiderContent>
    )
  }

  const renderMainContent = () => {
    // const { API_USERS_ME_STATUS } = currentUser

    // if (!API_USERS_ME_STATUS || API_USERS_ME_STATUS === "pending") {
    //   return <ContentLoading />
    // }
    // if (API_USERS_ME_STATUS === "rejected") {
    //   return <ContentLoadingError />
    // }

    return <CreateSchoolForm />
  }

  if (!URLHelper.isSecureSubDomain(window.location.href)) {
    window.location = URLHelper.getSecureURL("/profile/schools/new")
    return
  }

  return (
    <StyledPage>
      <StyledPageContent>
        <StyledPageContentCoverBackground>
          <div></div>
        </StyledPageContentCoverBackground>
        <StyledPageSider>{renderSider()}</StyledPageSider>
        <StyledMainContent>
          <div className="container">{renderMainContent()}</div>
        </StyledMainContent>
      </StyledPageContent>
    </StyledPage>
  )
}

export default view(CreateSchoolPage)
