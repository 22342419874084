import React from "react"
import { useHistory } from "react-router-dom"
import { view } from "@risingstack/react-easy-state"
import styled from "styled-components"
import { CheckCircleFilled } from "@ant-design/icons"
import { Input, Button, Form } from "antd"

import { BoxShadow, Color } from "constants/theme"

import FormIcon from "components/common/FormIcon"

const StyledFormWrapper = styled.div`
  position: relative;
  max-width: 400px;
  width: 100%;
  max-height: 400px;
  margin: auto;
  text-align: center;
  & > h1 {
    margin: 0px 0px 20px 0px;
    text-transform: uppercase;
  }
`

const StyledForm = styled.div`
  width: 100%;
  min-height: 200px;
  background: white;
  box-shadow: ${BoxShadow.card.normal};
  border-radius: 7px;
  & > form {
    padding: 0px 40px 40px 40px;
    border: 0px;
  }
`

const StyledFormHeader = styled.div`
  padding: 30px;
  & > .anticon {
    font-size: 3rem;
    color: ${Color.brandColor};
  }
  & > h1 {
    margin-top: 10px;
    text-transform: uppercase;
  }
`

const ForgotPasswordForm = ({ submitting, onSubmit }) => {
  const history = useHistory()
  const submitButtonLabel = submitting ? " Sending..." : "Send Password Reset Link"

  const handleRegisterButtonClick = () => {
    history.push("/register")
  }

  return (
    <StyledFormWrapper>
      <StyledForm>
        <StyledFormHeader>
          <CheckCircleFilled />
          <h1>Forgot Password</h1>
        </StyledFormHeader>
        <Form onFinish={onSubmit} className="login-form form-card">
          <Form.Item name="email" rules={[{ required: true, message: "Please enter registered email!" }]}>
            <Input prefix={<FormIcon type="mail" />} placeholder="Registered Email ID" />
          </Form.Item>
          <Button type="primary" htmlType="submit" className="login-button" loading={submitting}>
            {submitButtonLabel}
          </Button>

          <p className="switch-auth-link" style={{ marginTop: 20, marginBottom: 0 }}>
            Don't have an account?{" "}
            <Button type="link" style={{ padding: 0 }} onClick={handleRegisterButtonClick}>
              Register
            </Button>
          </p>
        </Form>
      </StyledForm>
    </StyledFormWrapper>
  )
}

export default view(ForgotPasswordForm)
