import React from "react"
import ContentLoader from "components/common/ContentLoader"
import ContentLoadingError from "components/common/ContentLoadingError"

export const staleWhileRevalidate = ({ status, render, data }) => {
  if (data) return render()
  if (status === "rejected") return <ContentLoadingError />
  return <ContentLoader />
}

export const networkOnly = ({ status, render, data }) => {
  if (!status) return <ContentLoader />
  if (status === "pending") return <ContentLoader />
  if (status === "rejected") return <ContentLoadingError />

  if (!data) return <ContentLoader />
  return render()
}
