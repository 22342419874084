import React from "react"
import PrivateRoute from "routes/PrivateRoute"
import Content from "components/common/layouts/Content"
import { view } from "@risingstack/react-easy-state"

const withAppLayout = (WrappedComponent) => (props) => (
  <Content>
    <WrappedComponent {...props} />
  </Content>
)

const AdminPrivateRoute = ({ component, withoutAppLayout, ...props }) => {
  const newComponent = withoutAppLayout ? component : withAppLayout(component)
  return <PrivateRoute loginRoute="/login" component={newComponent} {...props} />
}

export default view(AdminPrivateRoute)
