import { store } from "@risingstack/react-easy-state"

const globalStore = store({
  firebaseInstance: null,
  currentUser: {},
  currentSchool: {
    school: {},
    selectedItemChildren: [],
  },
  sider: {
    school: {},
    selectedItemChildren: [],
  },
  ui: {
    register: {},
    login: {},
    me: {},
    admin: {
      me: {},
      settings: {
        plans: {
          index: {},
        },
      },
      landing_pages: {
        index: {},
        show: {},
      },
      webinars: {
        index: {},
        new: {},
        show: {},
      },
      integrations: {
        zoom: {},
      },
      bank_details: {
        edit: {},
        show: {
          school: {},
        },
      },
    },
    guest: {
      courses: {
        index: {},
      },
    },
    users: {
      profile: {},
      courses: {
        show: {},
        purchase: {
          school: {},
          course: {},
        },
      },
      plans: {
        subscribe: {},
      },
    },
    student: {
      courses: {
        enrolled: {},
        index: {},
        show: {},
      },
      lectures: {
        show: {},
      },
      register: {},
    },
    courses: {
      index: {},
      new: {},
      information: {},
      curriculum: {},
    },
    lectures: {
      show: {},
    },
    school: {
      courses: {
        index: {},
        show: {},
        detail: {
          courseDetail: {},
        },
      },
    },
    leads: {
      landing_pages: {
        show: {},
      },
    },
  },
})

export default globalStore
