import React from "react"
import styled from "styled-components"
import { view } from "@risingstack/react-easy-state"

import { Color } from "constants/theme"

const StyledEmptyContent = styled.div`
  position: relative;
  top: 100px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 120px 50px 50px 50px;
  margin-bottom: 50px;
  box-sizing: content-box;

  & > h1 {
    font-size: 2rem;
    margin-top: 20px;
  }
  & > img {
    height: inherit;
  }
  & > a {
    color: ${Color.brandColor};
    margin-right: 10px;
    border: 2px solid transparent;
    border-radius: 30px;
    padding: 5px 20px;
    transition: all 0.5s;
    &:hover {
      border-color: ${Color.brandColor};
      cursor: pointer;
    }
  }
`

const EmptyContent = ({ title = "No Content Found", description = "", style = {} }) => {
  return (
    <StyledEmptyContent>
      <img src={process.env.PUBLIC_URL + "/images/ui-empty.svg"} alt="Empty" />
      <h1>{title}</h1>
      <p>{description}</p>
    </StyledEmptyContent>
  )
}

export default view(EmptyContent)
