import React, { useEffect } from "react"
import { view } from "@risingstack/react-easy-state"
// import { useRouteMatch } from "react-router-dom"

import { networkOnly } from "utils/render-strategies"
import globalStore from "store"

import LandingPagesService from "services/leads/landing-pages.service.js"

const LeadLandingPage = () => {
  const landingPageId = window.location.pathname.split("/")[2]
  const pageState = globalStore.ui.leads.landing_pages.show
  const { API_LANDING_PAGES_SHOW_STATUS, landing_page } = pageState

  useEffect(() => {
    const pageState = globalStore.ui.leads.landing_pages.show
    const fetchData = async () => {
      await LandingPagesService.show({ pageState, landingPageId })
    }
    fetchData()
  }, [landingPageId])

  const renderContent = () => {
    console.log("landing_page:", landing_page)
    const html = `
      <style>${landing_page.css}</style>
      ${landing_page.html}
    `
    return <div dangerouslySetInnerHTML={{ __html: html }}></div>
  }

  return networkOnly({
    status: API_LANDING_PAGES_SHOW_STATUS,
    data: landing_page,
    render: renderContent,
  })
}

export default view(LeadLandingPage)
