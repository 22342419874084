import React, { useEffect } from "react"
import { view } from "@risingstack/react-easy-state"
import styled from "styled-components"
import { useHistory } from "react-router-dom"
import { ArrowLeftOutlined, UserOutlined, BankOutlined, PlusCircleOutlined, PoweroffOutlined } from "@ant-design/icons"
import { Row, Button, Avatar } from "antd"

import { Color, BoxShadow } from "constants/theme"

import ContentLoading from "components/common/ContentLoading"
import ContentLoadingError from "components/common/ContentLoadingError"
import ProfileEditForm from "components/common/ProfileEditForm"

import globalStore from "store"

import UsersService from "services/users/users.service"

const StyledPage = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: ${Color.pageColor};
`

const StyledPageContent = styled.div`
  position: relative;
`

const StyledPageContentCoverBackground = styled.div`
  width: 100%;
  position: absolute;
  overflow: hidden;
  height: 100vh;
  & > div {
    min-width: calc(100vw + 100px);
    height: 400px;
    border-radius: 0px 0px 0px 20px;
    background-image: linear-gradient(20deg, ${Color.brandColor} 7%, #2dbde4 93%);
    position: absolute;
    -webkit-tranform-origin: bottom left;
    tranform-origin: bottom left;
    -webkit-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    transform: rotate(-10deg);
    left: -50px;
    top: -120px;
  }
`

const StyledPageSider = styled.div`
  position: fixed;
  width: 280px;
  left: 20px;
  top: 20px;
  min-height: calc(100vh - 40px);
  background: white;
  box-shadow: ${BoxShadow.card.normal};
  border-radius: 7px;
`

const StyledMainContent = styled.div`
  position: relative;
  width: calc(100vw - 380px); //Total padding(20*3) + Sider width(280)
  left: 360px;
  top: 20px;
  & > div.container {
    width: 100%;
    position: absolute;
    top: 60px;
    min-height: calc(100vh - 40px);
    border-radius: 7px;
  }
`

const StyledLink = styled.div`
  position: relative;
  & > button {
    padding: 0px;
    color: white;
    &:hover {
      color: white;
    }
  }
`

const StyledSiderContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  position: absolute;
  width: 100%;
  font-size: 1.5em;
  font-weight: bold;
  color: ${Color.brandColor};
  & > .school-name {
    padding: 20px 10px;
    text-align: center;
  }
  & > .ant-avatar {
    margin: 20px 0px 20px 0px;
  }
  & > h2,
  & > p {
    font-size: 0.8em;
    color: black;
    font-weight: normal;
  }
`
const StyledSiderMenu = styled.ul`
  margin: 30px 0px 0px 0px;
  padding: 0px;
  list-style-type: none;
  width: 100%;
  & > li {
    margin-bottom: -1px;
    border: 1px solid whitesmoke;
    & > button {
      height: 40px;
      color: black;
      text-align: left;
    }
  }
`

const ProfileEditPage = () => {
  const history = useHistory()
  const { currentSchool } = globalStore

  const menuItems = [
    {
      label: "My Schools",
      path: "/profile",
      icon: <BankOutlined />,
    },
    {
      label: "Create New School",
      path: "/profile/schools/new",
      icon: <PlusCircleOutlined />,
    },
    {
      label: "Logout",
      onClick: () => history.push("/logout"),
      icon: <PoweroffOutlined />,
    },
  ]

  useEffect(() => {
    const fetchPageData = async () => {
      const pageState = globalStore.ui.users.profile
      await UsersService.me({ pageState })

      globalStore.currentUser.user = JSON.parse(JSON.stringify(pageState.user))
    }
    fetchPageData()
  }, [])

  const renderSider = () => {
    const pageState = globalStore.ui.users.profile
    const { user } = pageState

    if (!user) return <ContentLoading />

    const menuItemOnClick = (menuItem) => {
      if (menuItem.onClick) return menuItem.onClick
      if (menuItem.path) return () => history.push(`${menuItem.path}`)
    }

    const renderUserAvatar = () => {
      if (user.display_picture_url) {
        return <Avatar shape="square" size={80} src={user.display_picture_url} />
      }
      return <Avatar shape="square" size={80} icon={<UserOutlined />} />
    }

    console.log("currentSchool", currentSchool)

    return (
      <StyledSiderContent>
        <div className="school-name">{currentSchool.school ? currentSchool.school.name : "CourseMango"}</div>
        {renderUserAvatar()}
        <h2>{user.name}</h2>
        <p>{user.email}</p>
        <StyledSiderMenu>
          {menuItems.map((mi, index) => (
            <li key={index}>
              <Button type="link" onClick={menuItemOnClick(mi)}>
                {mi.icon} {mi.label}
              </Button>
            </li>
          ))}
        </StyledSiderMenu>
      </StyledSiderContent>
    )
  }

  const renderMainContent = () => {
    const pageState = globalStore.ui.users.profile
    const { user, API_USERS_ME_STATUS } = pageState

    if (!API_USERS_ME_STATUS || API_USERS_ME_STATUS === "pending") {
      return <ContentLoading />
    }
    if (API_USERS_ME_STATUS === "rejected") {
      return <ContentLoadingError />
    }

    return (
      <React.Fragment>
        <ProfileEditForm user={user} />
      </React.Fragment>
    )
  }

  return (
    <StyledPage>
      <StyledPageContent>
        <StyledPageContentCoverBackground>
          <div></div>
        </StyledPageContentCoverBackground>
        <StyledPageSider>{renderSider()}</StyledPageSider>
        <StyledMainContent>
          <Row>
            <StyledLink>
              <Button type="link" onClick={() => history.push("/profile")}>
                <ArrowLeftOutlined /> Go to Profile
              </Button>
            </StyledLink>
          </Row>
          <div className="container">{renderMainContent()}</div>
        </StyledMainContent>
      </StyledPageContent>
    </StyledPage>
  )
}

export default view(ProfileEditPage)
