import React, { useState } from "react"
import { view } from "@risingstack/react-easy-state"
import styled from "styled-components"
import { useHistory, Redirect } from "react-router-dom"
import { ArrowLeftOutlined } from "@ant-design/icons"
import { Row, Col, Button, message } from "antd"

import LoginForm from "components/common/LoginForm"
import globalStore from "store"
import * as AuthHelper from "helpers/auth.helper"
import * as URLHelper from "helpers/url.helper"
// import firebaseApp from "firebase-app"

import { Color } from "constants/theme"

import UsersService from "services/users/users.service"

const StyledPage = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: ${Color.pageColor};
`

const StyledPageContent = styled.div`
  position: relative;
`

const StyledPageContentCoverBackground = styled.div`
  width: 100%;
  position: absolute;
  overflow: hidden;
  height: 100vh;
  & > div {
    min-width: calc(100vw + 100px);
    height: 400px;
    border-radius: 0px 0px 0px 20px;
    background-image: linear-gradient(20deg, ${Color.brandColor} 7%, #2dbde4 93%);
    position: absolute;
    -webkit-tranform-origin: bottom left;
    tranform-origin: bottom left;
    -webkit-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    transform: rotate(-10deg);
    left: -50px;
    top: -120px;
  }
`

const StyledLink = styled.div`
  & > button {
    padding: 0px;
    color: white;
    &:hover {
      color: white;
    }
  }
`

const StyledMainContent = styled.div`
  padding: 20px 100px;
  @media only screen and (max-width: 576px) {
    padding: 20px;
  }
  & > div.form-row {
    margin-top: 50px;
  }
`

const StyledFormRowLeft = styled.div`
  & > * {
    color: white;
  }
  & > h1 {
    font-size: 2rem;
    font-weight: bold;
    margin: 0px;
  }
  & > p {
    font-size: 1.2rem;
  }
`

const StyledFormRowRight = styled.div`
  width: 555px;
  @media only screen and (max-width: 576px) {
    width: 100%;
  }
`

const LoginPage = () => {
  const history = useHistory()
  const pageState = globalStore.ui.login
  const { state } = history.location || {}

  const [submitting, setSubmitting] = useState(false)

  const handleSubmit = async (values) => {
    setSubmitting(true)
    await UsersService.login({ values, pageState })
    setSubmitting(false)

    const { user, userErrors, serverStatus } = pageState

    message.destroy()

    if (serverStatus.status === 201 && user.jwt) {
      AuthHelper.setToken(user.jwt)
      message.success("Loggedin successfully")

      URLHelper.redirectToReferrer({ referrer: document.referrer, userToken: user.jwt, history })
      return
    }

    if (serverStatus.status === 403) {
      console.log("userError", userErrors)
      message.error("Please enter valid login credentials")
      return
    }

    message.error("Oops!! something went wrong, please try later")
  }

  // const handleSocialLogin = async (provider) => {
  //   setSocialButtonLoading(provider)

  //   let firebaseResponse = null

  //   console.log("firebaseInstance", firebaseInstance)
  //   if (provider === "facebook") {
  //     firebaseResponse = await firebaseInstance.signInWithFacebook()
  //   } else {
  //     firebaseResponse = await firebaseInstance.signInWithGoogle()
  //     console.log("firebaseResponse google", firebaseResponse)
  //   }

  //   console.log("firebaseInstance", firebaseInstance)
  //   if (firebaseInstance.user) {
  //     console.log("JSON.stringify(user, null, 2)", JSON.stringify(firebaseInstance.user, null, 2))
  //   }

  //   // if (firebaseResponse) {
  //   //   const { additionalUserInfo, credential } = firebaseResponse

  //   //   console.log(firebaseResponse)

  //   //   const postRequest = {
  //   //     accessToken: credential.accessToken,
  //   //     idToken: credential.idToken,
  //   //     provider_id: additionalUserInfo.profile,
  //   //   }

  //   //   // await UsersService.socialLogin({ pageState, values: postRequest })

  //   //   console.log("pageState social login", pageState)
  //   // } else {
  //   //   message.error("Oops!! something went wrong while login")
  //   //   setSocialButtonLoading(null)
  //   // }
  // }

  if (AuthHelper.isLoggedIn()) return <Redirect to="/" />

  if (!URLHelper.isSecureSubDomain(window.location)) {
    window.location = URLHelper.getSecureURL("/login")
    return
  }

  return (
    <StyledPage>
      <StyledPageContent>
        <StyledPageContentCoverBackground>
          <div></div>
        </StyledPageContentCoverBackground>
        <StyledMainContent>
          <Row>
            <StyledLink>
              <Button type="link" onClick={() => history.push("/")}>
                <ArrowLeftOutlined /> Go to Home
              </Button>
            </StyledLink>
          </Row>
          <div className="form-row">
            <Row type="flex" justify="space-between" align="top">
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <StyledFormRowLeft>
                  <h1>CourseMango</h1>
                  <p>
                    Spread Your Knowledge <br /> Create and Sell Online Courses
                  </p>
                </StyledFormRowLeft>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <StyledFormRowRight>
                  <LoginForm
                    submitting={submitting}
                    heading={false}
                    locationState={state}
                    onSubmit={handleSubmit}
                    // onSocialLogin={handleSocialLogin}
                  />
                </StyledFormRowRight>
              </Col>
            </Row>
          </div>
        </StyledMainContent>
      </StyledPageContent>
    </StyledPage>
  )
}

export default view(LoginPage)
