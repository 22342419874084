import React, { useState, useEffect } from "react"
import styled from "styled-components"
import dayjs from "dayjs"
import { view } from "@risingstack/react-easy-state"
import { UserOutlined } from "@ant-design/icons"
import { Comment, Avatar, List, Input, Form, Button, message } from "antd"

import { BoxShadow, Color } from "constants/theme"

import lectureCommentsServices from "services/student/lectureComments.service"

import globalStore from "store"

const relativeTime = require("dayjs/plugin/relativeTime")
dayjs.extend(relativeTime)

const { TextArea } = Input

const StyledCommentBox = styled.div`
  width: 100%;
  min-height: 30px;
  background: white;
  box-shadow: ${BoxShadow.card.normal};
  border-radius: 7px;
  margin-bottom: 30px;
  padding: 20px;
  & .ant-comment {
    padding: 10px;
    border-radius: 7px;
    background: #f3f3f3;
    margin: 15px 0px;
    box-shadow: 0px 0px 0px #00000012;
    transition: all 0.5s;
    &:hover {
      // box-shadow: 0px 3px 0px #00000012;
    }
    & .ant-comment-content-author-name {
      font-weight: bold;
    }
    & .ant-comment-content-author-time {
      color: #808080;
    }
  }
`
const StyledChildComment = styled.div`
  margin-left: 50px;
`

const StyledCenterItem = styled.div`
  margin: 20px 0px;
  text-align: center;
`

const StyledComment = styled.div`
  & > .ant-comment {
    border-left: ${(props) =>
      props.commentedBy === "admin"
        ? `5px solid ${Color.brandColor}`
        : props.commentedBy === "self"
        ? `5px solid green`
        : "0px"};
  }
`

const CommentList = view(
  ({ comments, replyCommentId, submitting, commentValue, onReplyClick, onChange, onSubmitComment }) => {
    const { currentUser } = globalStore

    console.log("currentUser", currentUser)

    const formattedComments = comments.map((c) => {
      let parentId = c.id

      const commentedBy = () => {
        if (c.commented_by_admin) return "admin"
        if (!currentUser.user) return "student"
        if (currentUser.user.id !== c.user.id) return "student"
        return "self"
      }

      if (c.ancestry) {
        parentId = c.ancestry.split("/").splice(-1)[0]
      }

      return {
        actions: [
          <span key="comment-basic-reply-to" onClick={() => onReplyClick(c.id, parentId)}>
            Reply
          </span>,
        ],
        commentId: c.id,
        ancestry: c.ancestry,
        commentedBy: commentedBy(),
        content: c.text,
        avatar: c.user.display_picture_url || <Avatar icon={<UserOutlined />} />,
        author: c.user.name,
        datetime: dayjs().to(dayjs(c.created_at)),
      }
    })

    return (
      <List
        dataSource={formattedComments}
        header={null}
        itemLayout="horizontal"
        renderItem={(props) => {
          const { commentId, commentedBy, ancestry, ...commentProps } = props

          const renderEditor = () => {
            if (!replyCommentId || replyCommentId !== commentId) {
              return null
            }

            return (
              <Comment
                avatar={<Avatar icon={<UserOutlined />} />}
                content={
                  <Editor
                    buttonText="Reply"
                    onChange={onChange}
                    onSubmit={onSubmitComment}
                    submitting={submitting}
                    value={commentValue}
                  />
                }
              />
            )
          }

          if (ancestry) {
            return (
              <StyledChildComment>
                <StyledComment commentedBy={commentedBy}>
                  <Comment {...commentProps} />
                </StyledComment>
                {renderEditor()}
              </StyledChildComment>
            )
          }

          return (
            <React.Fragment>
              <StyledComment commentedBy={commentedBy}>
                <Comment {...commentProps} />
              </StyledComment>
              {renderEditor()}
            </React.Fragment>
          )
        }}
      />
    )
  },
)

const Editor = view(({ buttonText = "Add Comment", onChange, onSubmit, submitting, value }) => (
  <div>
    <Form.Item>
      <TextArea rows={4} onChange={onChange} value={value} />
    </Form.Item>
    <Form.Item>
      <Button htmlType="submit" loading={submitting} onClick={onSubmit} type="primary">
        {buttonText}
      </Button>
    </Form.Item>
  </div>
))

const CommentsBox = ({ pageState, lectureId, lectureComments, lectureCommentsMeta }) => {
  const [loadingMoreComments, setLoadingMoreComments] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [comments, setComments] = useState([])
  const [commentValue, setCommentValue] = useState("")
  const [commentsMeta, setCommentsMeta] = useState({})
  const [replyCommentId, setReplyCommentId] = useState(null)
  const [replyParentId, setReplyParentId] = useState(null)

  useEffect(() => {
    setComments(lectureComments || [])
    setCommentsMeta(lectureCommentsMeta || {})
  }, [lectureComments, lectureCommentsMeta])

  const handleLoadMoreComments = async () => {
    setLoadingMoreComments(true)

    await lectureCommentsServices.index({ pageState, lectureId, nextPage: commentsMeta.next_page })

    setLoadingMoreComments(false)

    const { lecture_comments, lecture_comments_meta, lectureCommentsErrors } = pageState

    if (lectureCommentsErrors && lectureCommentsErrors.length > 0) {
      message.error("Failed to load comments")
      return
    }

    setComments([...comments, ...lecture_comments])
    setCommentsMeta(lecture_comments_meta || {})
  }

  const handleChange = (e) => {
    setCommentValue(e.target.value)
  }

  const handleReplyClick = (commentId, parentId) => {
    setReplyCommentId(commentId)
    setReplyParentId(parentId)
  }

  const handleSubmitComment = async () => {
    if (!commentValue) {
      return
    }

    setSubmitting(true)

    const postData = {
      lecture_comment: {
        parent_id: replyParentId || null,
        text: commentValue,
      },
    }

    await lectureCommentsServices.create({ pageState, lectureId, values: postData })

    setSubmitting(false)

    const { lecture_comments, lecture_comments_meta, lectureCommentsErrors } = pageState

    if (lecture_comments.length > comments.length) {
      message.success("Comment sent successfully.")
      setCommentValue("")
      setReplyCommentId(null)
      setReplyParentId(null)
      setComments(lecture_comments)
      setCommentsMeta(lecture_comments_meta || {})
      return
    }

    message.error(lectureCommentsErrors[0] || "Failed to send comment")
  }

  const renderRootCommentEditor = () => {
    if (replyCommentId) {
      return (
        <StyledCenterItem>
          <Button
            type="primary"
            onClick={() => {
              setReplyCommentId(null)
              setReplyParentId(null)
              setCommentValue("")
            }}
          >
            Add Comment
          </Button>
        </StyledCenterItem>
      )
    }

    return (
      <Comment
        avatar={<Avatar icon={<UserOutlined />} />}
        content={
          <Editor onChange={handleChange} onSubmit={handleSubmitComment} submitting={submitting} value={commentValue} />
        }
      />
    )
  }

  return (
    <StyledCommentBox>
      <h2>Comments ({`${comments.length}`})</h2>
      {renderRootCommentEditor()}
      <div className="comments">
        {comments.length > 0 && (
          <CommentList
            comments={comments}
            submitting={submitting}
            commentValue={commentValue}
            replyCommentId={replyCommentId}
            onReplyClick={handleReplyClick}
            onChange={handleChange}
            onSubmitComment={handleSubmitComment}
          />
        )}
        {commentsMeta.next_page ? (
          <StyledCenterItem>
            <Button loading={loadingMoreComments} onClick={handleLoadMoreComments}>
              Load more comments
            </Button>
          </StyledCenterItem>
        ) : (
          <StyledCenterItem>
            <p>-- No more comments --</p>
          </StyledCenterItem>
        )}
      </div>
    </StyledCommentBox>
  )
}

export default view(CommentsBox)
