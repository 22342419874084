import { API, generic } from "api"

export default class PlansService {
  static index = async ({ pageState }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_ADMIN_CM_PLANS_INDEX_STATUS",
      stateDataKey: ["plans", "current_plan"],
      stateErrorKey: ["plansErrors", "currentPlanErrors"],
      apiEndpoint: API.admin.cm.plans.index,
      apiUrlReplacements: {},
      apiData: {},
      errorMessage: "Plans could not be fetched",
    })
  }

  static show = async ({ pageState, planId, schoolId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_ADMIN_CM_PLANS_SHOW_STATUS",
      stateDataKey: ["plan", "current_plan", "user", "school"],
      stateErrorKey: ["plansErrors", "currentPlanErrors", "userErrors", "schoolErrors"],
      apiEndpoint: API.admin.cm.plans.show,
      apiUrlReplacements: { planId, schoolId },
      apiData: {},
      errorMessage: "Plan could not be fetched",
    })
  }
}
