import React, {lazy} from "react"
import { Link } from "react-router-dom"
import { view } from "@risingstack/react-easy-state"
import styled from "styled-components"
import { CheckCircleFilled } from "@ant-design/icons"
import { Input, Button, Form } from "antd"

import { BoxShadow, Color } from "constants/theme"

import FormIcon from "components/common/FormIcon"
let SocialLogin
if (process.env.REACT_APP_FIREBASE_API_KEY) {
  SocialLogin = lazy(() => import("components/common/SocialLogin/Index"))
}

const StyledFormWrapper = styled.div`
  position: relative;
  max-width: 400px;
  width: 100%;
  max-height: 400px;
  margin: auto;
  text-align: center;
  & > h1 {
    margin: 0px 0px 20px 0px;
    text-transform: uppercase;
  }
`

const StyledForm = styled.div`
  width: 100%;
  min-height: 200px;
  background: white;
  box-shadow: ${BoxShadow.card.normal};
  border-radius: 7px;
  & > form {
    padding: 20px 40px 10px 40px;
    border: 0px;
  }
`

const StyledFormHeader = styled.div`
  padding: 20px 30px;
  & > .anticon {
    font-size: 3rem;
    color: ${Color.brandColor};
  }
  & > h1 {
    margin-top: 10px;
    margin-bottom: 0px;
    text-transform: uppercase;
  }
`

const RegisterForm = ({ title = "Register", subTitle, submitting, onFinish, heading, errors }) => {
  const labelRegister = submitting ? " Logging in..." : "Register"

  const errorMessage = (inputFieldName) => {
    return errors && errors[inputFieldName] && errors[inputFieldName].length > 0
      ? errors[inputFieldName]
          .map((e) => `${inputFieldName[0].toUpperCase()}${inputFieldName.substr(1)} ${e}`)
          .join("<br/>")
      : null
  }

  const validateStatus = (inputFieldName) => {
    return errors && errors[inputFieldName] && errors[inputFieldName].length > 0 ? "error" : null
  }

  return (
    <StyledFormWrapper>
      {heading && (
        <React.Fragment>
          <h1>{title}</h1>
          <p>{subTitle}</p>
        </React.Fragment>
      )}
      <StyledForm>
        <StyledFormHeader>
          <CheckCircleFilled />
          <h1>Register</h1>
        </StyledFormHeader>
        <Form onFinish={onFinish} className="register-form form-card">
          <Form.Item
            name="name"
            rules={[{ required: true, message: "Please enter your name!" }]}
            validateStatus={validateStatus("name")}
            help={errorMessage("name")}
          >
            <Input prefix={<FormIcon type="user" />} placeholder="Your name" />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[{ required: true, message: "Please enter email!" }]}
            validateStatus={validateStatus("email")}
            help={errorMessage("email")}
          >
            <Input prefix={<FormIcon type="mail" />} placeholder="Email" autoComplete="username" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please enter password!" }]}
            validateStatus={validateStatus("password")}
            help={errorMessage("password")}
          >
            <Input.Password
              type="password"
              autoComplete="current-password"
              prefix={<FormIcon type="lock" />}
              placeholder="Password"
            />
          </Form.Item>
          <Button type="primary" block htmlType="submit" className="register-button" loading={submitting}>
            {labelRegister}
          </Button>
          {process.env.REACT_APP_FIREBASE_API_KEY ? <SocialLogin /> : null}
          <p className="switch-auth-link" style={{ marginTop: 20, marginBottom: 0 }}>
            Already have an account? <Link to="/login">Login</Link>
          </p>
        </Form>
      </StyledForm>
    </StyledFormWrapper>
  )
}

export default view(RegisterForm)
