import { generic, API } from "api"

export default class PasswordsService {
  static create = async ({ pageState, values }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_PASSWORDS_CREATE_STATUS",
      stateDataKey: ["response"],
      stateErrorKey: ["responseErrors"],
      apiEndpoint: API.users.passwords.create,
      apiUrlReplacements: {},
      apiData: { ...values },
      errorMessage: "Password could not be created.",
    })
  }

  static edit = async ({ pageState, token }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_PASSWORDS_EDIT_STATUS",
      stateDataKey: ["response"],
      stateErrorKey: ["responseErrors"],
      apiEndpoint: API.users.passwords.edit,
      apiUrlReplacements: { token },
      apiData: {},
      errorMessage: "Password could not be edited.",
    })
  }

  static update = async ({ pageState, token, values }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_PASSWORDS_UPDATE_STATUS",
      stateDataKey: ["response"],
      stateErrorKey: ["responseErrors"],
      apiEndpoint: API.users.passwords.update,
      apiUrlReplacements: { token },
      apiData: { ...values },
      errorMessage: "Password could not be updated.",
    })
  }
}
