import queryString from "query-string"

export const getAdminCourseId = () => {
  const currentPath = window.location.pathname
  if (!currentPath.startsWith("/admin/courses")) return null

  const courseIdString = currentPath.split("/")[3]
  const courseId = parseInt(courseIdString, 10)
  if (courseId.toString() !== courseIdString) return null

  return courseId
}

export const getSecureURL = (pathname) => {
  const protocol = "https"

  return `${protocol}://secure.${process.env.REACT_APP_FRONTEND_HOST}${pathname}`
}

export const getSchoolRootURL = (school) => {
  if (school.domain) return school.domain

  const protocol = "https"

  return `${protocol}://${school.subdomain}.${process.env.REACT_APP_FRONTEND_HOST}`
}

export const isAppSubDomain = (url) => {
  const appDomains = ["", "app", "secure"]

  const currentSubDomain = getSubDomain(url)

  return appDomains.includes(currentSubDomain)
}

export const getCustomAppDomain = () => {
  const protocol = "https"

  return `${protocol}://custom.${process.env.REACT_APP_FRONTEND_HOST}`
}

export const isSecureSubDomain = (url) => {
  const currentSubDomain = getSubDomain(url)

  return ["secure"].includes(currentSubDomain)
}

export const getDomain = (url) => {
  try {
    const parsedUrl = new URL(url)

    return parsedUrl.host
  } catch {
    return ""
  }
}

export const getParsedUrl = (url) => {
  try {
    const parsedUrl = new URL(url)

    return parsedUrl
  } catch {
    return {}
  }
}

export const getSubDomain = (url) => {
  return getDomain(url).replace(`${process.env.REACT_APP_FRONTEND_HOST}`, "").replace(/\.$/, "")
}

export const isCustomDomain = (url) => {
  return !getDomain(url).includes(process.env.REACT_APP_FRONTEND_HOST)
}

export const redirectToReferrer = ({ referrer, userToken, history, defaultPath = "/profile" }) => {
  if (referrer && !isAppSubDomain(referrer)) {
    const { url, query = {} } = queryString.parseUrl(referrer)

    const newQuery = {
      ...query,
      token: userToken,
    }

    const stringifiedQuery = queryString.stringify(newQuery)

    window.location = [url, stringifiedQuery].join("?")
    return
  }

  history.push(defaultPath)

  return
}
