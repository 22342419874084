import React, { useState } from "react"
import styled from "styled-components"
import { Document, Page, pdfjs } from "react-pdf"
import { Button } from "antd"
import { view } from "@risingstack/react-easy-state"

import ContentLoading from "components/common/ContentLoading"

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const StyledPdfViewer = styled.div`
  min-width: 400px;
  & > .pdf-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > p {
      margin: 0px;
    }
  }
`

const PdfViewer = ({ attachedFile }) => {
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }

  const prevButtonClick = () => {
    if (pageNumber === 1) return

    setPageNumber((prevState) => prevState - 1)
  }

  const nextButtonClick = () => {
    if (pageNumber === numPages) return

    setPageNumber((prevState) => prevState + 1)
  }

  return (
    <StyledPdfViewer>
      <Document loading={<ContentLoading />} file={attachedFile.url} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} />
      </Document>
      <div className="pdf-footer">
        <p>
          Page {pageNumber} of {numPages}
        </p>
        <Button.Group>
          <Button onClick={prevButtonClick}>Prev</Button>
          <Button onClick={nextButtonClick}>Next</Button>
        </Button.Group>
      </div>
    </StyledPdfViewer>
  )
}

export default view(PdfViewer)
