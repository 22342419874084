import React, { useState } from "react"
import { view } from "@risingstack/react-easy-state"
import styled from "styled-components"
import { useHistory, Redirect } from "react-router-dom"
import { ArrowLeftOutlined } from "@ant-design/icons"
import { Row, Col, Button, message } from "antd"

import RegisterForm from "components/common/StudentRegisterForm"
import globalStore from "store"

import * as AuthHelper from "helpers/auth.helper"
import * as URLHelper from "helpers/url.helper"

import { Color } from "constants/theme"

import UsersService from "services/users/users.service"

const StyledPage = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: ${Color.pageColor};
`

const StyledPageContent = styled.div`
  position: relative;
`

const StyledPageContentCoverBackground = styled.div`
  width: 100%;
  position: absolute;
  overflow: hidden;
  height: 100vh;
  & > div {
    min-width: calc(100vw + 100px);
    height: 400px;
    border-radius: 0px 0px 0px 20px;
    background-image: linear-gradient(20deg, ${Color.brandColor} 7%, #2dbde4 93%);
    position: absolute;
    -webkit-tranform-origin: bottom left;
    tranform-origin: bottom left;
    -webkit-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    transform: rotate(-10deg);
    left: -50px;
    top: -120px;
  }
`

const StyledLink = styled.div`
  & > button {
    padding: 0px;
    color: white;
    &:hover {
      color: white;
    }
  }
`

const StyledMainContent = styled.div`
  padding: 20px 100px;
  @media only screen and (max-width: 576px) {
    padding: 20px;
  }
  & > div.form-row {
    margin-top: 50px;
  }
`

const StyledFormRowLeft = styled.div`
  & > * {
    color: white;
  }
  & > h1 {
    font-size: 2rem;
    font-weight: bold;
    margin: 0px;
  }
  & > p {
    font-size: 1.2rem;
  }
`

const StyledFormRowRight = styled.div`
  width: 555px;
  @media only screen and (max-width: 576px) {
    width: 100%;
  }
`

const RegisterPage = () => {
  const history = useHistory()
  const pageState = globalStore.ui.student.register
  const [submitting, setSubmitting] = useState(false)
  const [formErrors, setFormErrors] = useState({})

  const handleFinish = async (values) => {
    setSubmitting(true)
    await UsersService.register({ values, pageState })
    setSubmitting(false)

    const { user, userErrors, serverStatus } = pageState

    message.destroy()

    if (serverStatus.status === 201 && user.jwt) {
      AuthHelper.setToken(user.jwt)
      message.success("Registered successfully")

      // if (!redirect_url) {
      // This method is should be moved on separate file
      URLHelper.redirectToReferrer({ referrer: document.referrer, userToken: user.jwt, history })
      return
      // }
      // if (redirect_url.startsWith(window.location.origin)) {
      //   const pushPath = redirect_url.replace(window.location.origin, "")
      //   history.push(pushPath)
      // } else {
      //   window.location = redirect_url
      // }
      // return
    }

    if (serverStatus.status === 422) {
      message.error("Please enter valid details")

      if (userErrors) {
        setFormErrors(userErrors)
        return
      }
    }

    message.error("Oops!! something went wrong")
    return
  }

  if (AuthHelper.isLoggedIn()) return <Redirect to="/" />

  if (!URLHelper.isSecureSubDomain(window.location)) {
    window.location = URLHelper.getSecureURL("/register")
    return
  }

  return (
    <StyledPage>
      <StyledPageContent>
        <StyledPageContentCoverBackground>
          <div></div>
        </StyledPageContentCoverBackground>
        <StyledMainContent>
          <Row>
            <StyledLink>
              <Button type="link" onClick={() => history.push("/")}>
                <ArrowLeftOutlined /> Go to Home
              </Button>
            </StyledLink>
          </Row>
          <div className="form-row">
            <Row type="flex" justify="space-between" align="top">
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <StyledFormRowLeft>
                  <h1>CourseMango</h1>
                  <p>
                    Spread Your Knowledge <br /> Create and Sell Online Courses
                  </p>
                </StyledFormRowLeft>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <StyledFormRowRight>
                  <RegisterForm onFinish={handleFinish} submitting={submitting} heading={false} errors={formErrors} />
                </StyledFormRowRight>
              </Col>
            </Row>
          </div>
        </StyledMainContent>
      </StyledPageContent>
    </StyledPage>
  )
}

export default view(RegisterPage)
