import React from "react"
import styled from "styled-components"
import { LoadingOutlined } from "@ant-design/icons"
import { view } from "@risingstack/react-easy-state"

const StyledContentLoader = styled.div`
  position: relative;
  top: 100px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 120px 50px 50px 50px;
  margin-bottom: 50px;
  box-sizing: content-box;
  & > i {
    font-size: 2rem;
  }
  & > p {
    margin-top: 20px;
  }
`

const ContentLoading = ({ title = "Please wait...", style = {} }) => {
  return (
    <StyledContentLoader style={style}>
      <LoadingOutlined />
      <p>{title}</p>
    </StyledContentLoader>
  )
}

export default view(ContentLoading)
