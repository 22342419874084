import React from "react"
import styled from "styled-components"
import { ArrowLeftOutlined } from "@ant-design/icons"
import { Row, Button } from "antd"
import { view } from "@risingstack/react-easy-state"

import { Color } from "constants/theme"

const StyledPage = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: ${Color.pageColor};
`

const StyledPageContent = styled.div`
  position: relative;
`

const StyledPageContentCoverBackground = styled.div`
  width: 100%;
  position: absolute;
  overflow: hidden;
  height: 100vh;
  & > div {
    min-width: calc(100vw + 100px);
    height: 400px;
    border-radius: 0px 0px 0px 20px;
    background-image: linear-gradient(20deg, ${Color.brandColor} 7%, #2dbde4 93%);
    position: absolute;
    -webkit-tranform-origin: bottom left;
    tranform-origin: bottom left;
    -webkit-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    transform: rotate(-10deg);
    left: -50px;
    top: -120px;
  }
`

const StyledMainContent = styled.div`
  position: relative;
  width: calc(100vw - 40px);
  left: 20px;
  top: 20px;
  & > div.container {
    width: 100%;
    position: absolute;
    top: 60px;
    min-height: calc(100vh - 40px);
    border-radius: 7px;
  }
`

const StyledLink = styled.div`
  position: relative;
  & > button {
    padding: 0px;
    color: white;
    &:hover {
      color: white;
    }
  }
`

const StyledContent = styled.div`
  & > h1 {
    color: white;
  }
`

const Terms = ({ history }) => {
  const renderMainContent = () => {
    return (
      <StyledContent>
        <h1>Terms & Conditions</h1>
      </StyledContent>
    )
  }

  return (
    <StyledPage>
      <StyledPageContent>
        <StyledPageContentCoverBackground>
          <div></div>
        </StyledPageContentCoverBackground>
        <StyledMainContent>
          <Row>
            <StyledLink>
              <Button type="link" onClick={() => history.push("/")}>
                <ArrowLeftOutlined /> Go to Home
              </Button>
            </StyledLink>
          </Row>
          <div className="container">{renderMainContent()}</div>
        </StyledMainContent>
      </StyledPageContent>
    </StyledPage>
  )
}

export default view(Terms)
