import React, { useState, useEffect } from "react"
import { view } from "@risingstack/react-easy-state"
import styled from "styled-components"
import dayjs from "dayjs"
import queryString from "query-string"
import { ArrowLeftOutlined, LoadingOutlined } from "@ant-design/icons"
import { Row, Col, Button, message } from "antd"

import { Color } from "constants/theme"
import * as AuthHelper from "helpers/auth.helper"
import * as URLHelper from "helpers/url.helper"
import globalStore from "store"
import PlansService from "services/admin/plans.service"
import SubscriptionsService from "services/admin/subscriptions.service"
import StyledPage from "components/common/styled-components/StyledPage"
import ContentLoading from "components/common/ContentLoading"

const StyledPageContent = styled.div`
  position: relative;
`

const StyledPageContentCoverBackground = styled.div`
  width: 100%;
  position: absolute;
  overflow: hidden;
  height: 100vh;
  & > div {
    min-width: calc(100vw + 100px);
    height: 400px;
    border-radius: 0px 0px 0px 20px;
    background-image: linear-gradient(20deg, ${Color.brandColor} 7%, #2dbde4 93%);
    position: absolute;
    -webkit-tranform-origin: bottom left;
    tranform-origin: bottom left;
    -webkit-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    transform: rotate(-10deg);
    left: -50px;
    top: -120px;
  }
`
const StyledContainer = styled.div`
  width: ${(props) => (props.width ? props.width : "auto")};
  margin: auto;
`

const StyledMainContent = styled.div`
  position: absolute;
  width: 100%;
  padding: 20px;
  @media only screen and (max-width: 576px) {
    padding: 20px;
  }
  & > div.form-row {
    margin-top: 50px;
  }
`

const StyledSchoolCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
  margin-bottom: 30px;
  // background: white;
  // box-shadow: 0px 17px 20px 0px #4a4a4a14;
  // border-radius: 7px;
  overflow: hidden;
  & > h2 {
    color: white;
  }
  & > div.image-box {
    padding: 3px;
    border: 2px solid white;
    border-radius: 7px;
    margin-bottom: 20px;
    overflow: hidden;
    & > img {
      width: 100px;
      height: 100px;
      border-radius: 5px;
    }
  }
`

const StyledCurrentUser = styled.div`
  padding: 20px;
  margin-bottom: 30px;
  background: white;
  box-shadow: 0px 17px 20px 0px #4a4a4a14;
  border-radius: 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > p {
    margin: 0px;
  }
  & > button {
    padding: 0px;
  }
`

const StyledPlanCard = styled.div`
  padding: 20px;
  background: ${(props) => (props.bgColor ? props.bgColor : "white")};
  box-shadow: 0px 17px 20px 0px #4a4a4a14;
  border-radius: 7px;
  text-align: center;
  & > div {
    & > img {
      width: 80%;
      margin: 50px auto;
    }
  }
  & > h2 {
    color: ${(props) => (props.bgColor ? "white" : "black")};
  }
  & > p {
    color: ${(props) => (props.bgColor ? "white" : "black")};
  }
`

const StyledLink = styled.div`
  position: relative;
  & > button {
    padding: 0px;
    color: white;
    &:hover {
      color: white;
    }
  }
`

const PlanSubscribePage = ({ history, match }) => {
  const { planId, schoolId } = match.params
  const [frequency, setFrequency] = useState(null)
  const [currency, setCurrency] = useState(null)
  const [school, setSchool] = useState(null)
  const [plan, setPlan] = useState(null)
  const [currentUser, setCurrentUser] = useState(null)
  const [currentPaidActivatedPlanId, setCurrentPaidActivatedPlanId] = useState(null)
  const [initialActivePlan, setInitialActivePlan] = useState({})
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false)
  const [planUpgraded, setPlanUpgraded] = useState(false)

  useEffect(() => {
    const { query } = queryString.parseUrl(window.location.href)

    const validFrequencies = ["monthly", "annual"]
    const validCurrencies = ["inr"]
    let invalidQuery = false

    if (!query) {
      invalidQuery = true
    }
    if (!query.frequency || !validFrequencies.includes(query.frequency)) {
      invalidQuery = true
    }
    if (!query.currency || !validCurrencies.includes(query.currency)) {
      invalidQuery = true
    }

    if (invalidQuery) {
      message.error("Billing frequency and(or) currency are not set or invalid")
      window.location = `${URLHelper.getSchoolRootURL(school)}/admin/setting/plan`
      return
    }

    const { frequency, currency } = query

    setFrequency(frequency)
    setCurrency(currency)
  }, [school])

  useEffect(() => {
    const fetchData = async () => {
      const pageState = globalStore.ui.users.plans.subscribe

      await PlansService.show({ pageState, planId, schoolId })

      const { serverStatus, school, plan, user, current_plan } = pageState

      if (!serverStatus || serverStatus.status !== 200) {
        message.error("Oop!! something went wrong, please try later")
        return
      }

      if (parseInt(school.id, 10) !== schoolId) {
        message.error("Oop!! something went wrong, please try later")
        return
      }

      setSchool(school)
      setPlan(plan)
      setCurrentUser(user)
      setInitialActivePlan(current_plan)

      if (current_plan && !current_plan.free) {
        setCurrentPaidActivatedPlanId(current_plan.id)
      }

      console.log("course purchase page", pageState)
    }

    fetchData()
  }, [planId, schoolId])

  const findPlanPrice = () => {
    if (!plan) return 0

    const priceObj = plan.prices.find((price) => price.currency === currency)

    return priceObj ? priceObj[`${frequency}_price`] : 0
  }

  const handleBackButton = () => {
    window.location = `${URLHelper.getSchoolRootURL(school)}/admin/settings/plan`
  }

  const handleVisitSchool = () => {
    window.location = `${URLHelper.getSchoolRootURL(school)}/admin/`
  }

  const handleMakePayment = async () => {
    const pageState = {}
    const postData = {
      currency,
      school_id: school.id,
      frequency: frequency === "monthly" ? "monthly" : "yearly",
    }

    setSubmitButtonLoading(true)
    if (currentPaidActivatedPlanId) {
      await SubscriptionsService.changePlan({ pageState, planId: plan.id, values: postData })
      setSubmitButtonLoading(false)

      const { serverStatus } = pageState

      if (serverStatus.status !== 200) {
        message.error("Failed to upgrade plan, please try later")
        return
      }

      setCurrentPaidActivatedPlanId(plan.id)
      setPlanUpgraded(true)
      message.success("Plan changed successfully")

      return
    }

    await SubscriptionsService.create({ pageState, planId: plan.id, values: postData })

    const { subscription } = pageState

    if (!subscription || !subscription.external_id) {
      message.error("Oops!! something went wrong. Please try after sometime.")
      return
    }
    const razorpayOptions = {
      key: process.env.REACT_APP_RAZORPAY_KEY_ID,
      subscription_id: subscription.external_id,
      amount: findPlanPrice() * 100, // 2000 paise = INR 20, amount in paisa
      name: "CourseMango Plan",
      description: `${plan.name} - ${currency.toUpperCase()} ${findPlanPrice()}`,
      handler: async (response) => {
        message.destroy()
        const razorpayPaymentId = response.razorpay_payment_id
        if (!razorpayPaymentId) {
          message.error("Oops!! something went wrong")
          setSubmitButtonLoading(false)

          return
        }
        const pageState = {}
        const postData = {
          ...response,
          school_id: school.id,
        }
        await SubscriptionsService.activate({ pageState, planId: plan.id, values: postData })

        setSubmitButtonLoading(false)

        const { subscription } = pageState

        if (!subscription || !subscription.cm_plan_id) {
          message.error("Oops!!, something went wrong. Please visit contact us page if money has been deducted.")
          return
        }
        setCurrentPaidActivatedPlanId(plan.id)
        setPlanUpgraded(true)
        message.success("Plan updated successfully")
        // Redirect to thank you page
        return
      },
    }
    const razorpay = new window.Razorpay(razorpayOptions)
    razorpay.on("payment.error", ({ error }) => {
      console.log("errors", error)
      message.destroy()
      message.error(error.description)
      setSubmitButtonLoading(false)
      return
    })
    razorpay.open()
  }

  const handleLogout = () => {
    history.push("/logout")
  }

  const renderPlanDetailCard = () => {
    if (planUpgraded || initialActivePlan.id === plan.id) {
      return (
        <StyledPlanCard>
          <div>
            <img src={process.env.PUBLIC_URL + "/images/ui-success.svg"} alt="payment-success" />
          </div>
          <h2>
            {} Upgraded your plan to <strong>{plan.name}</strong>
          </h2>
          <p>
            Your new plan is <strong>{`${currency.toUpperCase()} ${findPlanPrice()}`}</strong> billed for a{" "}
            <strong>{frequency === "monthly" ? "month" : "year"}</strong>.
          </p>
          <Button type="primary" onClick={handleVisitSchool}>
            Visit School
          </Button>
        </StyledPlanCard>
      )
    }
    if (initialActivePlan.free) {
      return (
        <StyledPlanCard>
          <h2>
            Upgrade your plan to <strong>{plan.name}</strong>
          </h2>
          <p>
            Your new plan will be <strong>{`${currency.toUpperCase()} ${findPlanPrice()}`}</strong> billed{" "}
            <strong>{frequency}</strong>. Your next billing cycle is {renderNextBillingCycle()}.
          </p>
          <Button type="primary" loading={submitButtonLoading} onClick={handleMakePayment}>
            Make Payment
          </Button>
        </StyledPlanCard>
      )
    }
    return (
      <StyledPlanCard>
        <h2>
          Change plan <strong>{initialActivePlan.name}</strong> to <strong>{plan.name}</strong>
        </h2>
        <p>
          Your new plan will be <strong>{`${currency.toUpperCase()} ${findPlanPrice()}`}</strong> billed{" "}
          <strong>{frequency}</strong>.
        </p>
        <Button type="primary" loading={submitButtonLoading} onClick={handleMakePayment}>
          Change Plan
        </Button>
      </StyledPlanCard>
    )
  }

  const renderMainContent = () => {
    if (!plan || !currentUser) {
      return (
        <StyledContainer>
          <ContentLoading />
        </StyledContainer>
      )
    }

    return (
      <StyledContainer width="60%">
        {renderSchoolCard()}
        <StyledCurrentUser>
          <p>
            Currently you are loggedin as <strong>{currentUser.email}</strong>
          </p>
          <Button type="link" onClick={handleLogout}>
            Logout
          </Button>
        </StyledCurrentUser>
        {renderPlanDetailCard()}
      </StyledContainer>
    )
  }

  const renderNextBillingCycle = () => {
    const unit = frequency === "monthly" ? "month" : "year"

    return <strong>{dayjs().add(1, unit).format("DD MMM YYYY")}</strong>
  }

  const renderSchoolCard = () => {
    if (!school) {
      return (
        <StyledSchoolCard>
          <LoadingOutlined />
        </StyledSchoolCard>
      )
    }

    return (
      <StyledSchoolCard>
        <div className="image-box">
          <img src={school.display_picture_url} alt="" />
        </div>
        <h2>{school.name}</h2>
      </StyledSchoolCard>
    )
  }

  if (!AuthHelper.isLoggedIn()) {
    message.warning("Please login to continue")
    return
  }

  if (!URLHelper.isSecureSubDomain(window.location)) {
    window.location = URLHelper.redirectToReferrer({ referrer: document.referrer, history })
    return
  }

  return (
    <StyledPage>
      <StyledPageContent>
        <StyledPageContentCoverBackground>
          <div></div>
        </StyledPageContentCoverBackground>
        <StyledMainContent>
          <Row justify="space-between">
            <Col>
              <StyledLink>
                <Button type="link" onClick={handleBackButton}>
                  <ArrowLeftOutlined /> Go to Plans
                </Button>
              </StyledLink>
            </Col>
            <Col></Col>
          </Row>
          <div className="container">{renderMainContent()}</div>
        </StyledMainContent>
      </StyledPageContent>
    </StyledPage>
  )
}

export default view(PlanSubscribePage)
