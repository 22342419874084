import { deserialise } from "kitsu-core"
import { message } from "antd"

import { API, apiClient, call, generic } from "api"

export default class SchoolsService {
  static create = async ({ values, pageState }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_SCHOOLS_CREATE_STATUS",
      stateDataKey: "school",
      stateErrorKey: "createSchoolErrors",
      apiEndpoint: API.admin.schools.create,
      apiUrlReplacements: {},
      apiData: { school: values },
      errorMessage: "School could not be created.",
    })

    if (pageState.createSchoolErrors) {
      pageState.stepStatus = "process"
      return
    }

    if (pageState.school) {
      pageState.currentStep = "done"
      pageState.stepStatus = "process"
    }
  }

  static launch = async ({ pageState }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_SCHOOLS_LAUNCH_STATUS",
      stateDataKey: ["school"],
      stateErrorKey: ["schoolErrors"],
      apiEndpoint: API.admin.schools.launch,
      apiUrlReplacements: {},
      apiData: {},
      errorMessage: "School could not be launched.",
    })
  }

  static update = async ({ pageState, schoolId, values }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_SCHOOLS_LAUNCH_UPDATE_STATUS",
      stateDataKey: ["school"],
      stateErrorKey: ["schoolErrors"],
      apiEndpoint: API.admin.schools.update,
      apiUrlReplacements: { schoolId },
      apiData: { ...values },
      errorMessage: "School could not be updated.",
    })
  }

  static uploadDisplayPicture = async ({ pageState, schoolId, formData }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_SCHOOLS_UPLOAD_DISPLAY_PICTURE_STATUS",
      stateDataKey: ["school"],
      stateErrorKey: ["schoolErrors"],
      apiEndpoint: API.admin.schools.upload_display_picture,
      apiUrlReplacements: { schoolId },
      apiData: formData,
      errorMessage: "School's display picture could not be updated.",
    })
  }

  static removeDisplayPicture = async ({ pageState, schoolId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_SCHOOLS_REMOVE_DISPLAY_PICTURE_STATUS",
      stateDataKey: ["school"],
      stateErrorKey: ["schoolErrors"],
      apiEndpoint: API.admin.schools.remove_display_picture,
      apiUrlReplacements: { schoolId },
      apiData: {},
      errorMessage: "School's display picture could not be deleted.",
    })
  }

  static paymentDetails = async ({ pageState }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_SCHOOLS_PAYMENT_DETAILS_STATUS",
      stateDataKey: ["school"],
      stateErrorKey: ["schoolErrors"],
      apiEndpoint: API.admin.schools.payment_details,
      apiUrlReplacements: {},
      apiData: {},
      errorMessage: "Payment details could not be fetched.",
    })
  }

  static updateGstDetails = async ({ pageState, values }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_SCHOOLS_UPDATE_GST_DETAILS_STATUS",
      stateDataKey: ["school"],
      stateErrorKey: ["schoolErrors"],
      apiEndpoint: API.admin.schools.update_gst_details,
      apiUrlReplacements: {},
      apiData: { ...values },
      errorMessage: "GST details could not be updated.",
    })
  }

  static updateTheme = async ({ pageState, values }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_SCHOOLS_UPDATE_THEME_STATUS",
      stateDataKey: ["school"],
      stateErrorKey: ["schoolErrors"],
      apiEndpoint: API.admin.schools.update_theme,
      apiUrlReplacements: {},
      apiData: { ...values },
      errorMessage: "Theme could not be updated.",
    })
  }

  static claimDNSEntry = async ({ pageState }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_SCHOOLS_CLAIM_DNS_ENTRY_STATUS",
      stateDataKey: ["school"],
      stateErrorKey: ["schoolErrors"],
      apiEndpoint: API.admin.schools.claim_dns_entry,
      apiUrlReplacements: {},
      apiData: {},
      errorMessage: "DNS Status could not be updated.",
    })
  }

  static me = async ({ pageState }) => {
    let response
    try {
      pageState.API_SCHOOLS_ME_STATUS = "pending"
      const { method, url } = API.admin.schools.me
      response = await call(apiClient()[method], url, {})
    } catch (e) {
      pageState.API_SCHOOLS_ME_STATUS = "rejected"
      if (e.name === "ServerNotReachableError") {
        message.error("Unable to reach server, please try after some time")
        pageState.serverNotReachable = true
        return
      }
      message.error("An error occured, please try after some time")
      throw e
    }

    console.log("response", response)
    const deserialisedData = await deserialise(response.data)
    console.log("deserialisedData", deserialisedData)

    if (deserialisedData.errors) {
      delete pageState.school
      pageState.schoolErrors = deserialisedData.errors
      return
    }

    pageState.school = deserialisedData.data
    delete pageState.schoolErrors
    pageState.API_SCHOOLS_ME_STATUS = "fulfilled"
  }
}
