import { deserialise } from "kitsu-core"
import { message } from "antd"

import * as AuthHelper from "helpers/auth.helper"
import { API, apiClient, call, generic } from "api"

export default class UsersService {
  static me = async ({ pageState }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_ME_STATUS",
      stateDataKey: ["user"],
      stateErrorKey: ["userErrors"],
      apiEndpoint: API.users.me,
      apiUrlReplacements: {},
      apiData: {},
      errorMessage: "User data could not be created.",
    })
  }

  static update = async ({ pageState, values }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_UPDATE_STATUS",
      stateDataKey: ["user"],
      stateErrorKey: ["userErrors"],
      apiEndpoint: API.users.update,
      apiUrlReplacements: {},
      apiData: { user: { ...values } },
      errorMessage: "User data could not be updated.",
    })
  }

  static uploadDisplayPicture = async ({ pageState, formData }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_UPDATE_DISPLAY_PICTURE_STATUS",
      stateDataKey: ["user"],
      stateErrorKey: ["userErrors"],
      apiEndpoint: API.users.change_display_picture,
      apiUrlReplacements: {},
      apiData: formData,
      errorMessage: "User data could not be updated.",
    })
  }

  static removeDisplayPicture = async ({ pageState }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_REMOVE_DISPLAY_PICTURE_STATUS",
      stateDataKey: ["user"],
      stateErrorKey: ["userErrors"],
      apiEndpoint: API.users.remove_display_picture,
      apiUrlReplacements: {},
      apiData: {},
      errorMessage: "User's profile picture could not be removed.",
    })
  }

  // FIXME: have to move in separate service - tokens.service
  static login = async ({ pageState, values, serializedResponse = false }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_LOGIN_STATUS",
      stateDataKey: ["user"],
      stateErrorKey: ["userErrors"],
      apiEndpoint: API.users.login,
      apiUrlReplacements: {},
      apiData: { auth: { ...values } },
      errorMessage: "Failed to login.",
      serializedResponse,
    })
  }

  static socialLogin = async ({ pageState, values, serializedResponse = false }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_SOCIAL_LOGIN_STATUS",
      stateDataKey: ["user"],
      stateErrorKey: ["userErrors"],
      apiEndpoint: API.users.tokens.social_login,
      apiUrlReplacements: {},
      apiData: { auth: { ...values } },
      errorMessage: "Failed to login.",
      serializedResponse,
    })
  }

  static register = async ({ pageState, values }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_REGISTER_STATUS",
      stateDataKey: ["user", "redirect_url"],
      stateErrorKey: ["userErrors", "redirectUrlErrors"],
      apiEndpoint: API.users.register,
      apiUrlReplacements: {},
      apiData: { user: { ...values } },
      errorMessage: "Failed to register.",
    })
  }

  static register_old = async ({ values, pageState }) => {
    let response
    try {
      pageState.API_USERS_REGISTER_STATUS = "pending"
      const { method, url } = API.users.register
      response = await call(apiClient()[method], url, { user: values })
    } catch (e) {
      pageState.API_USERS_REGISTER_STATUS = "rejected"
      if (e.name === "ServerNotReachableError") {
        message.error("Unable to reach server, please try after some time")
        pageState.currentStep = "account"
        pageState.stepStatus = "error"
        pageState.API_USERS_REGISTER_STATUS = "rejected"
        pageState.serverNotReachable = true
        return
      }
      message.error("An error occurred. Please try after some time")
      throw e
    }

    // console.log('response', response)
    const deserialisedData = await deserialise(response.data.user)
    // console.log('deserialisedData', deserialisedData)

    if (deserialisedData.errors) {
      pageState.registerErrors = deserialisedData.errors
      pageState.API_USERS_REGISTER_STATUS = "fulfilled"
      return
    }

    delete pageState.registerErrors

    if (!deserialisedData.data || (deserialisedData.data && !deserialisedData.data.jwt)) {
      message.error("Error logging in. Please try the Login page.")
      pageState.API_USERS_REGISTER_STATUS = "fulfilled"
      return
    }

    AuthHelper.setToken(deserialisedData.data.jwt)
    // AuthHelper.setUser(deserialisedData.data)

    const deserialisedRedirectUrl = await deserialise(response.data.redirect_url)
    console.log("deserialisedRedirectUrl", deserialisedRedirectUrl)
    pageState.redirectUrl = deserialisedRedirectUrl.data.redirect_url

    pageState.API_USERS_REGISTER_STATUS = "fulfilled"
  }

  static login_old = async ({ values, pageState }) => {
    let response
    try {
      pageState.API_USERS_LOGIN_STATUS = "pending"
      const { method, url } = API.users.login
      response = await call(apiClient()[method], url, { auth: values })
    } catch (e) {
      pageState.API_USERS_LOGIN_STATUS = "rejected"
      if (e.name === "ServerNotReachableError") {
        message.error("Unable to reach server, please try after some time")
        pageState.serverNotReachable = true
        return
      }
      message.error("An error occurred. Please try after some time")
      throw e
    }

    // console.log('response', response)
    const deserialisedData = await deserialise(response.data)
    // console.log('deserialisedData', deserialisedData)

    if (deserialisedData.errors) {
      pageState.registerErrors = deserialisedData.errors
      pageState.stepStatus = "process"
      return
    }

    delete pageState.registerErrors

    if (!deserialisedData.data || (deserialisedData.data && !deserialisedData.data.jwt)) {
      message.error("Error logging in. Please try the Login page.")
      return
    }

    AuthHelper.setToken(deserialisedData.data.jwt)
    // AuthHelper.setUser(deserialisedData.data)

    pageState.API_USERS_LOGIN_STATUS = "fulfilled"
  }
}
