import React, { useEffect } from "react"
import styled from "styled-components"
import { view } from "@risingstack/react-easy-state"
import { useHistory } from "react-router-dom"
import { UserOutlined } from "@ant-design/icons"
import { Dropdown, Menu, Avatar } from "antd"

import UsersService from "services/users/users.service"
import SchoolsService from "services/users/schools.service"
import { Color, Height, BoxShadow } from "constants/theme"
import * as AuthHelper from "helpers/auth.helper"
import * as URLHelper from "helpers/url.helper"
import globalStore from "store"

const StyledHeader = styled.header`
  height: ${Height.header}px;
  background-color: ${Color.headerBgColor};
  color: ${Color.brandColor};
  box-shadow: ${BoxShadow.header};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
`

const StyledBrandName = styled.div`
  color: ${Color.brandColor};
  font-weight: 500;
  font-size: 1.2em;
`

const StyledMenu = styled.ul`
  padding: 0px;
  margin: 0px;
  height: ${Height.header}px;
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
`
const StyledMenuItem = styled.li`
  margin: 0px 15px;
  min-width: ${Height.header}px;
  border: 2px solid transparent;
  border-radius: 30px;
  padding: 5px 20px;
  transition: all 0.5s;
  &:hover {
    border-color: ${Color.brandColor};
    cursor: pointer;
  }
`

const StyledProfileDropdown = styled.div`
  display: flex;
  justify-content: content;
  align-items: center;
  width: ${Height.header - 10}px;
  height: ${Height.header - 10}px;
  border-radius: 50%;
  & > * {
    color: white;
    background-color: ${Color.brandColor};
  }
`

const StyledAuthLinks = styled.div`
  & > a {
    color: ${Color.brandColor};
    margin-right: 10px;
    border: 2px solid transparent;
    border-radius: 30px;
    padding: 5px 20px;
    transition: all 0.5s;
    &:hover {
      border-color: ${Color.brandColor};
      cursor: pointer;
    }
  }
`

const defaultMenu = [
  {
    label: "My Courses",
    path: "/courses/enrolled",
  },
  {
    label: "All Courses",
    path: "/courses",
  },
  {
    label: "Contact",
    path: "/contact",
  },
]

const Header = ({ menu = defaultMenu, hideAuthLink }) => {
  const history = useHistory()
  const { currentUser, currentSchool } = globalStore

  useEffect(() => {
    const { currentUser, currentSchool } = globalStore

    if (!AuthHelper.isLoggedIn()) return

    if (currentUser.user && currentSchool.school) return

    if (!currentUser.user) {
      const fetchUserData = async () => {
        await UsersService.me({ pageState: currentUser })
      }
      fetchUserData()
    }

    if (!currentSchool.school) {
      const fetchSchoolData = async () => {
        await SchoolsService.me({ pageState: currentSchool })
      }
      fetchSchoolData()
    }
  }, [])

  // Fetch school data if not already fetched
  useEffect(() => {
    const pageState = globalStore.currentSchool
    const { school } = pageState

    // Call the API only once
    if (!!school.id || !AuthHelper.isLoggedIn()) return

    const fetchData = async () => {
      await SchoolsService.me({ pageState })
    }

    fetchData()
  }, [])

  const handleLogout = () => {
    history.push("/logout")
  }

  const dropdownMenu = (
    <Menu>
      <Menu.Item key="0" disabled>
        <h4>{currentUser.user ? currentUser.user.name : ""}</h4>
        <p>{currentUser.user ? currentUser.user.email : ""}</p>
      </Menu.Item>
      <Menu.Item key="1">
        <a href="/profile">Profile</a>
      </Menu.Item>
      <Menu.Item key="2">
        <a href="/users/subscriptions">Manage Subscriptions</a>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="3">
        <div onClick={handleLogout}>Logout</div>
      </Menu.Item>
    </Menu>
  )

  const BrandName = ({ children }) => {
    return <StyledBrandName>{children}</StyledBrandName>
  }

  const renderAdminLink = () => {
    const { school } = globalStore.currentSchool
    console.log("currentSchool.school", currentSchool.school)
    if (!school.id) return
    if (!school["current_user_admin?"]) return

    return (
      <StyledMenuItem key={menu.length} onClick={() => history.push("/admin")}>
        Admin
      </StyledMenuItem>
    )
  }

  const MenuItems = () => {
    return (
      <StyledMenu>
        {menu.map((m, index) => (
          <StyledMenuItem key={index} onClick={() => history.push(`${m.path}`)}>
            {m.label}
          </StyledMenuItem>
        ))}
        {renderAdminLink()}
      </StyledMenu>
    )
  }

  const ProfileDropdown = () => {
    return (
      <Dropdown overlay={dropdownMenu} trigger={["click"]}>
        <StyledProfileDropdown>
          <Avatar icon={<UserOutlined />} />
        </StyledProfileDropdown>
      </Dropdown>
    )
  }

  const AuthLinks = () => {
    const handleLoginClick = (e) => {
      e.preventDefault()
      window.location = URLHelper.getSecureURL("/login")
    }

    const handleRegisterClick = (e) => {
      e.preventDefault()
      window.location = URLHelper.getSecureURL("/register")
    }

    return (
      <StyledAuthLinks>
        <a href="# " onClick={handleLoginClick}>
          Login
        </a>
        <a href="# " onClick={handleRegisterClick}>
          Register
        </a>
      </StyledAuthLinks>
    )
  }

  if (!AuthHelper.isLoggedIn()) {
    return (
      <StyledHeader>
        <BrandName>
          <img src="/logo.png" alt="CourseMango Logo" height="32" width="32" /> CourseMango
        </BrandName>
        {!hideAuthLink && <AuthLinks></AuthLinks>}
      </StyledHeader>
    )
  }

  return (
    <StyledHeader>
      <BrandName>
        <img src="/logo.png" alt="CourseMango Logo" height="32" width="32" /> CourseMango
      </BrandName>
      <MenuItems />
      <ProfileDropdown></ProfileDropdown>
    </StyledHeader>
  )
}

export default view(Header)
