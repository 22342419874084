import { API, generic } from "api"

export default class CoursesService {
  static show = async ({ pageState, courseId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_COURSES_SHOW_STATUS",
      stateDataKey: ["course", "school", "user"],
      stateErrorKey: ["courseErrors", "schoolErrors", "userErrors"],
      apiEndpoint: API.users.courses.show,
      apiUrlReplacements: { courseId },
      apiData: {},
      errorMessage: "Course could not be fetched",
    })
  }
}
