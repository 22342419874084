import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { view } from "@risingstack/react-easy-state"
import styled from "styled-components"
import { CheckCircleFilled } from "@ant-design/icons"
import { Input, Button, Form, message } from "antd"

import { BoxShadow, Color } from "constants/theme"

import FormIcon from "components/common/FormIcon"

import SchoolsService from "services/admin/schools.service"

const StyledFormWrapper = styled.div`
  position: relative;
  max-width: 500px;
  width: 100%;
  max-height: 400px;
  margin: auto;
  text-align: center;
  & > h1 {
    margin: 0px 0px 20px 0px;
    text-transform: uppercase;
  }
`

const StyledForm = styled.div`
  width: 100%;
  min-height: 200px;
  background: white;
  box-shadow: ${BoxShadow.card.normal};
  border-radius: 7px;
  & > form {
    padding: 0px 40px 40px 40px;
    border: 0px;
  }
`

const StyledFormHeader = styled.div`
  padding: 30px;
  & > .anticon {
    font-size: 3rem;
    color: ${Color.brandColor};
  }
  & > h1 {
    margin-top: 10px;
    text-transform: uppercase;
  }
`

const CreateSchoolForm = () => {
  const history = useHistory()
  const pageState = {}

  const [submitting, setSubmitting] = useState(false)
  const [errors, setErrors] = useState([])

  const labelCreate = submitting ? " Creating..." : "Create New School"

  const handleFinish = async (values) => {
    setSubmitting(true)
    await SchoolsService.create({ pageState, values })
    setSubmitting(false)

    const { serverStatus, schoolErrors } = pageState

    if (schoolErrors && schoolErrors.length > 0) {
      setErrors(schoolErrors)
      return
    }
    message.destroy()
    if (serverStatus.status !== 201) {
      message.error("Oops!! something went wrong while creating school.")
      return
    }

    message.success("School created successfully.")

    history.push("/profile")

    console.log("pageState create school form", pageState)
  }

  return (
    <StyledFormWrapper>
      <StyledForm>
        <StyledFormHeader>
          <CheckCircleFilled />
          <h1>Create School</h1>
        </StyledFormHeader>
        <Form onFinish={handleFinish} className="register-form form-card">
          <div style={{ textAlign: "left", marginBottom: 0 }}>
            <strong>What do you want to call your school?</strong>
            <p style={{ marginBottom: 5 }}>(You can change this later)</p>
          </div>
          <Form.Item
            name="name"
            style={{ margin: "20px 0px 30px 0px" }}
            rules={[{ required: true, message: "Please enter your school's name!" }]}
            validateStatus={errors && errors.name && errors.name.length > 0 ? "error" : null}
            help={errors && errors.name && errors.name.length > 0 ? errors.name.join("<br/>") : null}
          >
            <Input prefix={<FormIcon type="bank" />} placeholder="Your School's Name" />
          </Form.Item>
          <Button type="primary" htmlType="submit" className="register-button" loading={submitting}>
            {labelCreate}
          </Button>
        </Form>
      </StyledForm>
    </StyledFormWrapper>
  )
}

export default view(CreateSchoolForm)
