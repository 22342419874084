import React, { useEffect } from "react"
import { BrowserRouter as Router } from "react-router-dom"
import { view } from "@risingstack/react-easy-state"
import { isMobile } from "react-device-detect"
import { generateThemeColor, changeAntdTheme } from "mini-dynamic-antd-theme"

import { Color } from "constants/theme"

import Routes from "routes"

import ErrorBoundary from "components/common/ErrorBoundary"
import SchoolsService from "services/users/schools.service"
import * as URLHelper from "helpers/url.helper"
import globalStore from "store/index"

import "antd/dist/antd.less"
import "./App.css"

changeAntdTheme(generateThemeColor(Color.brandColor))

function App() {
  const currenSchool = globalStore.currenSchool

  useEffect(() => {
    const fetchData = async () => {
      await SchoolsService.me({ pageState: currenSchool })

      const { school } = currenSchool

      if (school && school.theme_data) {
        const { primary_color } = school.theme_data

        changeAntdTheme(generateThemeColor(primary_color))
      }
    }

    if (!URLHelper.isAppSubDomain) {
      fetchData()
    }
  }, [currenSchool])

  return (
    <div className={isMobile ? "mobile-view" : ""}>
      <ErrorBoundary>
        <Router>
          <Routes />
        </Router>
      </ErrorBoundary>
    </div>
  )
}

export default view(App)
