import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { view } from "@risingstack/react-easy-state"
import { useHistory } from "react-router-dom"
import { ReadOutlined } from "@ant-design/icons"
import { Tooltip, Button, message } from "antd"

import * as AuthHelper from "helpers/auth.helper"
import * as URLHelper from "helpers/url.helper"
import globalStore from "store"
import EnrollmentsService from "services/student/enrollments.service"

import StyledCardBackground from "components/common/styled-components/StyledCardBackground"
import StyledCourseCard from "components/common/styled-components/StyledCourseCard"

import CurrencyUtil from "utils/currency"

const StyledCourseName = styled.div`
  margin-bottom: 10px;
  font-size: 1rem;
  color: white;
  background: #00000066;
  padding: 5px;
  border-radius: 4px;
  & > h3 {
    color: white;
  }
  & > p {
    margin: 0px;
  }
`

const StyledActionButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 1.5em;
  & > div {
    & > i {
      color: white;
    }
  }
`

const CourseCard = ({ course, paymentAllowed, school }) => {
  const history = useHistory()
  const { courseId } = history.location.state || {}
  const storeKey = AuthHelper.isLoggedIn() ? "student" : "guest"
  const pageState = globalStore.ui[storeKey].courses.index

  const [enrollCourseId, setEnrollCourseId] = useState(null)

  // TODO: Why are we using useEffect here?
  useEffect(() => {
    if (!courseId || course.id !== courseId) return

    const { courses = [] } = pageState

    const selectedCourse = courses.find((c) => c.id === courseId)

    if (!selectedCourse || selectedCourse.enrolled) return

    const { plan } = selectedCourse

    if (plan.free) {
      handleEnrollFreeCourse(selectedCourse.id)
      return
    }

    handlePaidCourse(selectedCourse)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseId, course.id])

  const handleEnrolledCourse = (courseId) => {
    if (!URLHelper.isAppSubDomain(window.location)) {
      history.push(`/courses/${courseId}`)
      return
    }

    window.location = `${URLHelper.getSchoolRootURL(school)}/courses/${courseId}`
    return
  }

  const handleEnrollFreeCourse = async (courseId) => {
    if (!AuthHelper.isLoggedIn()) {
      message.info("You need to login to enroll course.")
      history.push({
        pathname: "/login",
        state: { courseId },
      })
      return
    }

    setEnrollCourseId(courseId)

    const pageState = {}

    await EnrollmentsService.create({ pageState, courseId })

    const { courseErrors, API_ENROLLMENT_CREATE_STATUS } = pageState

    message.destroy()

    if (courseErrors) {
      handleEnrolledFailure()
      return
    }

    if (API_ENROLLMENT_CREATE_STATUS === "rejected") {
      handleEnrolledFailure("Couldn't enroll in this course, try later")
      return
    }

    message.info("You have enrolled in this course successfully.")

    history.push(`/courses/${courseId}`)
  }

  const handlePaidCourse = (courseDetails) => {
    if (!AuthHelper.isLoggedIn()) {
      message.info("You need to login to enroll course.")
      history.push({
        pathname: "/login",
        state: { courseId: course.id },
      })
      return
    }

    if (!paymentAllowed) {
      const { school } = globalStore.currentSchool

      const paymentUrl = URLHelper.getSecureURL(`/schools/${school.id}/courses/${course.id}/purchase`)

      window.location = paymentUrl

      return
    }

    const { plan } = courseDetails

    message.destroy()

    if (!plan) {
      message.error("Oops!! something went wrong, try later")
      return
    }

    const razorpayOptions = {
      key: process.env.REACT_APP_RAZORPAY_KEY_ID,
      amount: parseFloat(plan.price) * 100, // 2000 paise = INR 20, amount in paisa
      name: "CourseMango",
      description: "CourseMango's couse plan",
      handler: async (response) => {
        const razorpayPaymentId = response.razorpay_payment_id

        message.destroy()

        if (!razorpayPaymentId) {
          message.error("Oops!! something went wrong")
          return
        }

        setEnrollCourseId(courseDetails.id)

        const postData = {
          razorpay_payment_id: razorpayPaymentId,
          school_id: school.id,
        }

        await EnrollmentsService.razorpayPaymentVerification({
          pageState,
          courseId: courseDetails.id,
          values: postData,
        })

        setEnrollCourseId(null)

        const { course, courseErrors } = pageState

        if (courseErrors && courseErrors.length > 0) {
          const errorMessage = typeof courseErrors[0] === "string" ? courseErrors[0] : "Oops!! something went wrong"

          message.error(errorMessage)
          return
        }

        if (!course.enrolled) {
          message.error("Failed to enroll in this course")
          return
        }

        message.success("You have enrolled this course successfully.")
        handleEnrolledCourse(course.id)
      },
    }

    const razorpay = new window.Razorpay(razorpayOptions)

    razorpay.on("payment.error", ({ error }) => {
      console.log("errors", error)
      message.destroy()
      message.error(error.description)
      return
    })
    razorpay.open()
  }

  const handleEnrolledFailure = (errorMsg) => {
    message.error(errorMsg || "Failed to enroll, try again")
    setEnrollCourseId(null)
  }

  const renderButtonWithPrice = (plan) => {
    return (
      <React.Fragment>
        Enroll for <strong>{`${CurrencyUtil.getCurrency(plan.currency)}${plan.price}`}</strong>
      </React.Fragment>
    )
  }
  const renderActionButton = () => {
    const { id, enrolled, plan } = course

    if (enrolled) {
      return (
        <Button
          type="primary"
          size="large"
          block
          onClick={() => handleEnrolledCourse(id)}
          loading={enrollCourseId === id}
        >
          Learn
        </Button>
      )
    }

    if (!plan || plan.free) {
      return (
        <Button
          type="primary"
          size="large"
          block
          onClick={() => handleEnrollFreeCourse(id)}
          loading={enrollCourseId === id}
        >
          {enrollCourseId === id ? "Wait..." : "Enroll for free"}
        </Button>
      )
    }

    return (
      <Button
        type="primary"
        size="large"
        block
        onClick={() => handlePaidCourse(course)}
        loading={enrollCourseId === id}
      >
        {enrollCourseId === id ? "Wait..." : renderButtonWithPrice(plan)}
      </Button>
    )
  }

  return (
    <StyledCourseCard>
      <StyledCardBackground url={course.display_picture_url}>
        {!course.display_picture_url && <ReadOutlined />}
      </StyledCardBackground>
      <div className="course-details">
        <StyledActionButton>
          <div>{/* <HeartOutlined /> */}</div>
          <div>{/* <MoreOutlined /> */}</div>
        </StyledActionButton>
        <div>
          <Tooltip placement="top" title={course.name} arrowPointAtCenter>
            <StyledCourseName>
              <h3>{course.name}</h3>
              <p>{course.subtitle}</p>
            </StyledCourseName>
          </Tooltip>
          {renderActionButton()}
        </div>
      </div>
    </StyledCourseCard>
  )
}

export default view(CourseCard)
