import React from "react"
import { view } from "@risingstack/react-easy-state"
import styled from "styled-components"

import Sider from "components/common/layouts/Sider"
import { Color } from "constants/theme"

const StyledPage = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: ${Color.pageColor};
`

const StyledPageContent = styled.div`
  position: relative;
`

const StyledPageContentCoverBackground = styled.div`
  width: 100%;
  position: absolute;
  overflow: hidden;
  height: 100vh;
  & > div {
    min-width: calc(100vw + 100px);
    height: 400px;
    border-radius: 0px 0px 0px 20px;
    background-image: linear-gradient(20deg, ${Color.brandColor} 7%, #2dbde4 93%);
    position: absolute;
    -webkit-tranform-origin: bottom left;
    tranform-origin: bottom left;
    -webkit-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    transform: rotate(-10deg);
    left: -50px;
    top: -120px;
  }
`

const Content = ({ children }) => {
  return (
    <StyledPage>
      <StyledPageContent>
        <StyledPageContentCoverBackground>
          <div></div>
        </StyledPageContentCoverBackground>
        <Sider />
        {children}
      </StyledPageContent>
    </StyledPage>
  )
}

export default view(Content)
