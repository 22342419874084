import React, { useState } from "react"
import styled from "styled-components"
import { view } from "@risingstack/react-easy-state"

const StyledImageViewer = styled.div`
  position: ${(props) => (props.fullscreen ? "fixed" : "relative")};
  height: ${(props) => (props.fullscreen ? "100vh" : "auto")};
  top: ${(props) => (props.fullscreen ? "0px" : "none")};
  left: ${(props) => (props.fullscreen ? "0px" : "none")};
  z-index: ${(props) => (props.fullscreen ? "99" : "0")};
  transition: all 0.5s;
  min-width: 100%;
  cursor: pointer;
  background: #000000b5;
  overflow: auto;
  & > h2 {
    color: white;
    text-align: center;
    width: 80%;
    margin: 50px 10% 0px 10%;
  }
  & > img {
    width: ${(props) => (props.fullscreen ? "80%" : "100%")};
    margin: ${(props) => (props.fullscreen ? "50px 10%" : "0")};
  }
`

const ImageViewer = ({ attachedFile }) => {
  const [fullscreen, setFullscreen] = useState(false)

  const handleImageClick = () => {
    setFullscreen((prevState) => !prevState)
  }

  return (
    <StyledImageViewer fullscreen={fullscreen} onClick={handleImageClick}>
      {fullscreen && <h2>{attachedFile.file_name}</h2>}
      <img src={attachedFile.url} alt={attachedFile.file_name} />
    </StyledImageViewer>
  )
}

export default view(ImageViewer)
