import React, { useEffect } from "react"
import { view } from "@risingstack/react-easy-state"
import styled from "styled-components"
import { useHistory, useParams } from "react-router-dom"
import { ArrowLeftOutlined, ReadOutlined } from "@ant-design/icons"
import { Row, Col, Button, message } from "antd"

import { Color, BoxShadow } from "constants/theme"
import Header from "components/common/layouts/Header"
import ContentLoading from "components/common/ContentLoading"
import ContentLoadingError from "components/common/ContentLoadingError"
// import CoursePreviewVideo from "components/CoursePreviewVideo/Index"
import CourseContentSider from "components/common/CourseContentSider"
import StyledPage from "components/common/styled-components/StyledPage"

import globalStore from "store"

import CoursesService from "services/student/courses.service"

const StyledPageContent = styled.div`
  position: relative;
`

const StyledPageContentCoverBackground = styled.div`
  width: 100%;
  position: absolute;
  overflow: hidden;
  height: 100vh;
  & > div {
    min-width: calc(100vw + 100px);
    height: 400px;
    border-radius: 0px 0px 0px 20px;
    background-image: linear-gradient(20deg, ${Color.brandColor} 7%, #2dbde4 93%);
    position: absolute;
    -webkit-tranform-origin: bottom left;
    tranform-origin: bottom left;
    -webkit-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    transform: rotate(-10deg);
    left: -50px;
    top: -120px;
  }
`

const StyledMainContent = styled.div`
  padding: 20px 100px;
  & > div.container {
    margin-top: 30px;
    & > .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      & > .course-name {
        color: white;
        z-index: 1;
        font-size: 1.8em;
      }
      & > .action-buttons {
        & > button:nth-of-type(1) {
          margin-right: 10px;
        }
      }
    }
  }
`
const StyledLectureDetailContainer = styled.div``

const StyledLecturesListContainer = styled.div`
  background: white;
  min-height: 500px;
  background: white;
  box-shadow: ${BoxShadow.card.normal};
  border-radius: 7px;
`

const StyledCourseImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 9em;
  background: ${Color.brandColor};
  color: white;
  min-height: 200px;
  box-shadow: ${BoxShadow.card.normal};
  border-radius: 7px;
  background-image: url(${(props) => props.url});
  background-size: cover;
  &::before {
    content
  }
`

const StyledLink = styled.div`
  position: relative;
  & > button {
    padding: 0px;
    color: white;
    &:hover {
      color: white;
    }
  }
`

const StyledStartNextLectureCard = styled.div`
  background: white;
  box-shadow: ${BoxShadow.card.normal};
  border-radius: 7px;
  position: relative;
  display: flex;
  margin-bottom: 20px;
  padding: 10px;
  justify-content: flex-start;
  align-items: center;
  & > p {
    margin: 0px 15px;
    font-size: 1.2em;
  }
`

const CoursesShowPage = () => {
  const history = useHistory()
  const { courseId } = useParams()
  const pageState = globalStore.ui.student.courses.show

  useEffect(() => {
    const fetchPageData = async () => {
      await CoursesService.show({ pageState, courseId })
    }
    fetchPageData()
  }, [courseId, pageState])

  const renderPageContent = () => {
    const { course, next_lecture, courseErrors, serverStatus, API_COURSES_SHOW_STATUS } = pageState

    if (!API_COURSES_SHOW_STATUS) return <ContentLoading />
    if (API_COURSES_SHOW_STATUS === "pending") return <ContentLoading />
    if (API_COURSES_SHOW_STATUS === "rejected") return <ContentLoadingError />

    if (serverStatus.status !== 200) {
      let errorMessage = null
      if (courseErrors) {
        errorMessage = Array.isArray(courseErrors) ? courseErrors[0] : courseErrors
        message.error(errorMessage)
      }
      return <ContentLoadingError description={errorMessage} />
    }

    return (
      <div className="container">
        <div className="header">
          <div className="course-name">{course.name}</div>
          <div className="action-buttons"></div>
        </div>
        <StyledStartNextLectureCard>
          <Button
            disabled={!next_lecture}
            type="primary"
            onClick={() => history.push(`/courses/${courseId}/lectures/${next_lecture && next_lecture.id}`)}
          >
            Start Next Lecture
          </Button>
          <p>{next_lecture && next_lecture.name}</p>
        </StyledStartNextLectureCard>
        <Row gutter={20}>
          <Col xs={{ span: 24 }} lg={{ span: 16 }}>
            <StyledLectureDetailContainer>
              <StyledLecturesListContainer>
                <CourseContentSider sections={course.sections} courseId={course.id} />
              </StyledLecturesListContainer>
            </StyledLectureDetailContainer>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 8 }}>
            <StyledCourseImage url={course.display_picture_url}>
              {!course.display_picture_url && <ReadOutlined />}
            </StyledCourseImage>
          </Col>
        </Row>
      </div>
    )
  }

  return (
    <StyledPage>
      <Header />
      <StyledPageContent>
        <StyledPageContentCoverBackground>
          <div></div>
        </StyledPageContentCoverBackground>
        <StyledMainContent>
          <Row>
            <StyledLink>
              <Button type="link" onClick={() => history.push("/courses/enrolled")}>
                <ArrowLeftOutlined /> Go to My Courses
              </Button>
            </StyledLink>
          </Row>
          {renderPageContent()}
        </StyledMainContent>
      </StyledPageContent>
    </StyledPage>
  )
}

export default view(CoursesShowPage)
