import React from 'react'
import { LoadingOutlined } from '@ant-design/icons';
import { view } from "@risingstack/react-easy-state"

const ContentLoader = (props) => (
  <div className="content-loader">
    <LoadingOutlined /> <h3>Please wait...</h3>
  </div>
)

export default view(ContentLoader)
