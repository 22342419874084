import React from 'react'
import { view } from "@risingstack/react-easy-state"
import { Link } from 'react-router-dom'

const Error404Page = (props) => <div className="error-404-page">
  <h1>Page not found</h1>
  <Link to="/admin">Goto Dashboard</Link>
</div>

export default view(Error404Page)
