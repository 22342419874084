import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { ArrowLeftOutlined, LoadingOutlined } from "@ant-design/icons"
import { Row, Col, Button, message } from "antd"
import { view } from "@risingstack/react-easy-state"

import { Color } from "constants/theme"
import * as AuthHelper from "helpers/auth.helper"
import * as URLHelper from "helpers/url.helper"
import globalStore from "store"
import CoursesService from "services/users/courses.service"
import StyledPage from "components/common/styled-components/StyledPage"
import ContentLoading from "components/common/ContentLoading"
import CourseCard from "components/common/CourseCard"

const StyledPageContent = styled.div`
  position: relative;
`

const StyledPageContentCoverBackground = styled.div`
  width: 100%;
  position: absolute;
  overflow: hidden;
  height: 100vh;
  & > div {
    min-width: calc(100vw + 100px);
    height: 400px;
    border-radius: 0px 0px 0px 20px;
    background-image: linear-gradient(20deg, ${Color.brandColor} 7%, #2dbde4 93%);
    position: absolute;
    -webkit-tranform-origin: bottom left;
    tranform-origin: bottom left;
    -webkit-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    transform: rotate(-10deg);
    left: -50px;
    top: -120px;
  }
`
const StyledContainer = styled.div`
  width: ${(props) => (props.width ? props.width : "auto")};
  margin: auto;
`

const StyledMainContent = styled.div`
  position: absolute;
  width: 100%;
  padding: 20px;
  @media only screen and (max-width: 576px) {
    padding: 20px;
  }
  & > div.form-row {
    margin-top: 50px;
  }
`

const StyledSchoolLogo = styled.div`
  width: 100px;
  overflow: hidden;
  height: 100px;
  & > img {
    width: 100%;
  }
`

const StyledCurrentUser = styled.div`
  padding: 20px;
  margin-bottom: 30px;
  background: white;
  box-shadow: 0px 17px 20px 0px #4a4a4a14;
  border-radius: 7px;
  & > p {
    margin: 0px;
  }
`

const CoursePurchasePage = ({ history, match }) => {
  const { courseId, schoolId } = match.params
  const [school, setSchool] = useState(null)
  const [course, setCourse] = useState(null)
  const [currentUser, setCurrentUser] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      const pageState = globalStore.ui.users.courses.purchase

      await CoursesService.show({ pageState, courseId })

      const { serverStatus, school, course, user } = pageState

      if (!serverStatus || serverStatus.status !== 200) {
        message.error("Oop!! something went wrong, please try later")
        return
      }

      if (parseInt(school.id) !== schoolId) {
        message.error("Oop!! something went wrong, please try later")
        return
      }

      setSchool(school)
      setCourse(course)
      setCurrentUser(user)

      console.log("course purchase page", pageState)
    }

    fetchData()
  }, [courseId, schoolId])

  const handleBackButton = () => {
    URLHelper.redirectToReferrer({ referrer: document.referrer, history })
  }

  const renderMainContent = () => {
    if (!course || !currentUser) {
      return (
        <StyledContainer>
          <ContentLoading />
        </StyledContainer>
      )
    }

    return (
      <StyledContainer width="60%">
        <StyledCurrentUser>
          <p>
            Currently you are loggedin as <strong>{currentUser.email}</strong>
          </p>
        </StyledCurrentUser>
        <CourseCard course={course} paymentAllowed={true} school={school} />
      </StyledContainer>
    )
  }

  const renderSchoolLogo = () => {
    if (!school) {
      return (
        <StyledSchoolLogo>
          <LoadingOutlined />
        </StyledSchoolLogo>
      )
    }

    return (
      <StyledSchoolLogo>
        <img src={school.display_picture_url} alt="" />
      </StyledSchoolLogo>
    )
  }

  if (!AuthHelper.isLoggedIn()) {
    message.warning("Please login to continue")
    return
  }

  if (!URLHelper.isSecureSubDomain(window.location)) {
    window.location = URLHelper.redirectToReferrer({ referrer: document.referrer, history })
    return
  }

  return (
    <StyledPage>
      <StyledPageContent>
        <StyledPageContentCoverBackground>
          <div></div>
        </StyledPageContentCoverBackground>
        <StyledMainContent>
          <Row justify="space-between">
            <Col>{renderSchoolLogo()}</Col>
            <Col>
              <Button type="primary" onClick={handleBackButton}>
                <ArrowLeftOutlined /> Go to Courses
              </Button>
            </Col>
          </Row>
          <div className="container">{renderMainContent()}</div>
        </StyledMainContent>
      </StyledPageContent>
    </StyledPage>
  )
}

export default view(CoursePurchasePage)
