import styled from "styled-components"

// TODO: Move theme in src folder
import { Color } from "constants/theme"

const StyledPage = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: ${Color.pageColor};
`

export default StyledPage
