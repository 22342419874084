import React from "react"
import styled from "styled-components"
import { view } from "@risingstack/react-easy-state"

const StyledFullPage = styled.div`
  width: 100vw;
  min-height: 100vh;
  padding: 10px;
  background: white;
  & > .container {
    display: flex;
    justify-content: center;
    & > img {
      max-width: 800px;
      width: 60%;
    }
  }
`

const SchoolNotFound = () => {
  return (
    <StyledFullPage>
      <div className="container">
        <div>
          <img src={process.env.PUBLIC_URL + "/images/ui-launching.svg"} alt="Launching soon" />
        </div>
      </div>
    </StyledFullPage>
  )
}

export default view(SchoolNotFound)
