import React, { useState } from "react"
import styled from "styled-components"
import { InboxOutlined, LockOutlined, UserOutlined } from "@ant-design/icons"
import { Input, Button, Avatar, Modal, Upload, message, Form } from "antd"

import globalStore from "store"
import UsersService from "services/users/users.service"
import { view } from "@risingstack/react-easy-state"

const StyledProfileForm = styled.div`
  min-height: calc(100vh - 100px);
  background: white;
  box-shadow: 0px 17px 20px 0px #4a4a4a14;
  border-radius: 7px;
  & > form {
    max-width: 400px;
    margin: auto;
    padding: 30px 0px;
  }
`

const StyledProfilePicture = styled.div`
  padding: 50px 0px 0px 0px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const StyledChangePasswordLink = styled.div`
  text-align: center;
  margin-bottom: 10px;
`

const StyledModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  & > button {
    margi-leftn: 10px;
  }
`

const StyledFormButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ProfileEditForm = ({ user }) => {
  const pageState = globalStore.ui.users.profile
  const [form] = Form.useForm()
  const [changePassword, setChangePassword] = useState(false)
  const [uploadModalVisible, setUploadModalVisible] = useState(false)
  const [fileList, setFileList] = useState([])
  const [uploading, setUploading] = useState(false)
  const [removingProfilePicture, setRemovingProfilePicture] = useState(false)
  const [saving, setSaving] = useState(false)

  const handleFinish = async (values) => {
    message.destroy()

    setSaving(true)
    await UsersService.update({ pageState, values })
    setSaving(false)

    const { user, userErrors } = pageState

    if (userErrors && userErrors.length > 0) {
      if (typeof userErrors[0] === "string") {
        message.error(userErrors[0])
        return
      }

      message.error("Oops!! something went wrong")
      return
    }

    let resetFieldsNames = ["current_password"]

    if (values["password"]) {
      resetFieldsNames.push("password", "password_confirmation")
    }

    form.resetFields(resetFieldsNames)

    globalStore.ui.users.profile.user = user

    message.success("Profile details updated successfully.")
  }

  const handleUploadProfilePicture = async () => {
    message.destroy()

    if (fileList.length === 0) {
      message.info("Please add photo to upload")
      return
    }

    const formData = new FormData()

    formData.append("user[file]", fileList[0])

    const pageState = globalStore.ui.users.profile

    setUploading(true)
    await UsersService.uploadDisplayPicture({ pageState, formData })
    setUploading(false)

    const { userErrors } = pageState

    if (userErrors) {
      message.error("Failed to update profile picture")
      return
    }

    message.success("Profile picture updated successfully.")
    setUploadModalVisible(false)
    setFileList([])
  }

  const handleRemoveProfilePicture = async () => {
    const { user } = pageState

    if (!user || !user.display_picture_url) return

    setRemovingProfilePicture(true)
    await UsersService.removeDisplayPicture({ pageState })
    setRemovingProfilePicture(false)

    message.success("Profile picture removed successfully.")
    setUploadModalVisible(false)
  }

  const renderNewPasswordFormItems = () => {
    if (!changePassword) {
      if (user.password_random) return null

      return (
        <StyledChangePasswordLink>
          <Button type="link" onClick={() => setChangePassword(true)}>
            Change my password
          </Button>
        </StyledChangePasswordLink>
      )
    }

    return (
      <React.Fragment>
        <StyledChangePasswordLink>
          <Button type="link" onClick={() => setChangePassword(false)}>
            I don't want to {user.password_random ? "set" : "change"} my password
          </Button>
        </StyledChangePasswordLink>
        <Form.Item
          label="New Password"
          name="password"
          rules={[{ required: true, message: "Please enter new password!" }]}
        >
          <Input.Password
            prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            type="password"
            placeholder="New Password"
          />
        </Form.Item>
        <Form.Item
          label="Confirm Password"
          name="password_confirmation"
          rules={[{ required: true, message: "Please enter confirm new password!" }]}
        >
          <Input.Password
            prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            type="password"
            placeholder="Confirm New Password"
          />
        </Form.Item>
      </React.Fragment>
    )
  }

  const renderUserAvatar = () => {
    if (user.display_picture_url) {
      return <Avatar shape="square" size={80} src={user.display_picture_url} />
    }
    return <Avatar shape="square" size={80} icon={<UserOutlined />} />
  }

  const renderModalFooter = () => {
    return (
      <StyledModalFooter>
        <Button danger loading={removingProfilePicture} disabled={uploading} onClick={handleRemoveProfilePicture}>
          Remove Profile Picture
        </Button>
        <Button
          type="primary"
          loading={uploading}
          disabled={removingProfilePicture}
          onClick={handleUploadProfilePicture}
        >
          Upload
        </Button>
      </StyledModalFooter>
    )
  }

  const draggerProps = {
    accept: "image/png,image/jpg,image/jpeg",
    onRemove: (file) => {
      const index = fileList.indexOf(file)
      const newFileList = fileList.slice()
      newFileList.splice(index, 1)

      setFileList(newFileList)
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file])

      return false
    },
    fileList,
  }

  if (!user) return null

  return (
    <StyledProfileForm>
      <StyledProfilePicture>
        {renderUserAvatar()}
        <Button type="link" onClick={setUploadModalVisible}>
          Change Picture
        </Button>
      </StyledProfilePicture>
      <Form form={form} onFinish={handleFinish} layout="vertical" initialValues={{ name: user.name }}>
        <Form.Item label="Name" name="name" rules={[{ required: true, message: "Please enter your name!" }]}>
          <Input prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />} placeholder="Name" />
        </Form.Item>
        {renderNewPasswordFormItems()}
        {!user.password_random && (
          <Form.Item
            label="Current Password"
            name="current_password"
            rules={[{ required: true, message: "Please enter your current password" }]}
          >
            <Input.Password
              prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              type="password"
              placeholder="Current Password"
            />
          </Form.Item>
        )}
        <Form.Item>
          <StyledFormButtons>
            <Button type="primary" htmlType="submit" loading={saving}>
              {saving ? "Saving" : "Save"} Changes
            </Button>
            {user.password_random && !changePassword && (
              <Button onClick={() => setChangePassword(true)} disabled={saving}>
                Set Password
              </Button>
            )}
          </StyledFormButtons>
        </Form.Item>
      </Form>
      <Modal
        title="Upload Profile Picture"
        visible={uploadModalVisible}
        onCancel={() => setUploadModalVisible(false)}
        footer={renderModalFooter()}
      >
        <Upload.Dragger {...draggerProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag image to this area to upload</p>
          <p className="ant-upload-hint">
            Support for a single upload. Strictly prohibit from uploading company data or other band files
          </p>
        </Upload.Dragger>
      </Modal>
    </StyledProfileForm>
  )
}

export default view(ProfileEditForm)
