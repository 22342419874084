import { generic, API } from "api"

export default class SchoolsService {
  static me = async ({ pageState, schoolId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USERS_SCHOOLS_ME_STATUS",
      stateDataKey: ["school"],
      stateErrorKey: ["schoolErrors"],
      apiEndpoint: API.users.schools.me,
      apiUrlReplacements: { schoolId },
      apiData: {},
      errorMessage: "School data could not be fetched.",
    })
  }
}
