import React, {lazy, Suspense} from "react"
import { view } from "@risingstack/react-easy-state"
import { Route, Switch, useLocation, useHistory } from "react-router-dom"
import queryString from "query-string"

import ContentLoading from "components/common/ContentLoading"
import AdminPrivateRoute from "routes/AdminPrivateRoute"

// Users
import LoginPage from "pages/users/Login"
import LogoutPage from "pages/users/Logout"
import RegisterPage from "pages/users/Register"
import ProfilePage from "pages/users/Profile"
import ProfileEditPage from "pages/users/ProfileEdit"
import ForgotPasswordPage from "pages/users/ForgotPassword"
import ResetPasswordPage from "pages/users/ResetPassword"
import CoursePurchasePage from "pages/users/CoursePurchase"
import PlanSubscribePage from "pages/users/PlanSubscribe"
import CreateSchoolPage from "pages/users/CreateSchool"

// Students
import StudentCoursesIndexPage from "pages/students/courses/Index"
import StudentAllCoursesPage from "pages/students/courses/All"
import StudentCoursesShowPage from "pages/students/courses/Show"
import StudentLecturesShowPage from "pages/students/lectures/Show"

// Leads
import LeadLandingPage from "pages/leads/landing-pages/Show"

// Site
import PrivacyPage from "pages/site/Privacy"
import CancellationPage from "pages/site/CancellationPolicy"
import TermsPage from "pages/site/Terms"
import Error404Page from "pages/site/Error404"
import SchoolNotFound from "pages/site/SchoolNotFound"
import SiteHomePage from "pages/site/SiteHome"
import SchoolHomePage from "pages/site/SchoolHome"

import * as AuthHelper from "helpers/auth.helper"
import * as URLHelper from "helpers/url.helper"

// School Admin
const EditSchoolPage = lazy(() => import("pages/school-admin/EditSchool"))
const CoursesPage = lazy(() => import("pages/school-admin/courses/Index"))
const NewCoursePage = lazy(() => import("pages/school-admin/courses/New"))
const CourseInformationPage = lazy(() => import("pages/school-admin/courses/Information"))
const CourseCurriculumPage = lazy(() => import("pages/school-admin/courses/Curriculum"))
// const CoursePricingPage = lazy(() => import("pages/school-admin/courses/Pricing"))
const CourseCommentsPage = lazy(() => import("pages/school-admin/courses/Comments"))
// const CourseBundlePage = lazy(() => import("pages/school-admin/courses/Bundle"))
const NewLecturePage = lazy(() => import("pages/school-admin/courses/NewLecture"))
const NewSectionPage = lazy(() => import("pages/school-admin/courses/NewSection"))
const AdminSchoolPlanPage = lazy(() => import("pages/school-admin/plans/Index"))
const LandingPagesPage = lazy(() => import("pages/school-admin/landing-pages/Index"))
const LandingPagePage = lazy(() => import("pages/school-admin/landing-pages/Show"))
const WebinarsPage = lazy(() => import("pages/school-admin/webinars/Index"))
const NewWebinarPage = lazy(() => import("pages/school-admin/webinars/New"))
const WebinarPage = lazy(() => import("pages/school-admin/webinars/Show"))
const ZoomRedirectPage = lazy(() => import("pages/school-admin/zoom/Redirect"))
const ZoomAuthPage = lazy(() => import("pages/school-admin/zoom/Auth"))
const ZoomConnectPage = lazy(() => import("pages/school-admin/zoom/Connect"))
const BankDetailsShowPage = lazy(() => import("pages/school-admin/bank-details/Show"))
const BankDetailsEditPage = lazy(() => import("pages/school-admin/bank-details/Edit"))

const Routes = () => {
  // FIXME: Not getting query params
  // const { token, ...otherParams } = useParams()
  const location = useLocation()
  const history = useHistory()
  const params = queryString.parse(location.search)
  const { token, ...otherParams } = params

  // const shouldRedirectOnToken = () => {
  //   if (!token) return false
  //   return true
  // }

  // console.log("token", queryString.parse(location.search))

  const getHomePageComponent = () => {
    if (URLHelper.isAppSubDomain(window.location.href)) {
      return SiteHomePage
    }

    return SchoolHomePage
  }

  if (!!token) {
    // const { token, ...otherParams } = params
    AuthHelper.setToken(token)

    const redirectUrl = [location.pathname, queryString.stringify(otherParams)].filter((e) => !!e).join("?")

    console.log("redirectUrl", redirectUrl)

    history.replace(redirectUrl)
    return <ContentLoading />
  }

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <Route path="/">
        <Suspense fallback={<ContentLoading />}>
          <Switch>
            {/* School Admin */}
            {/* <AdminPrivateRoute exact path="/admin" component={DashboardPage} /> */}
            <AdminPrivateRoute exact path="/admin" component={CoursesPage} />
            <AdminPrivateRoute exact path="/admin/edit-school" component={EditSchoolPage} />
            <AdminPrivateRoute exact path="/admin/courses" component={CoursesPage} />
            <AdminPrivateRoute exact path="/admin/courses/new" component={NewCoursePage} />
            <AdminPrivateRoute exact path="/admin/courses/:courseId/information" component={CourseInformationPage} />
            <AdminPrivateRoute exact path="/admin/courses/:courseId/curriculum" component={CourseCurriculumPage} />
            <AdminPrivateRoute
              exact
              path="/admin/courses/:courseId/curriculum/lectures/:lectureId"
              component={NewLecturePage}
            />
            <AdminPrivateRoute exact path="/admin/courses/:courseId/curriculum/section/new" component={NewSectionPage} />
            <AdminPrivateRoute exact path="/admin/courses/:courseId/comments" component={CourseCommentsPage} />
            {/* <AdminPrivateRoute exact path="/admin/courses/:courseId/bundle" component={CourseBundlePage} /> */}

            <AdminPrivateRoute exact path="/admin/webinars" component={WebinarsPage} />
            <AdminPrivateRoute exact path="/admin/webinars/new" component={NewWebinarPage} />
            <AdminPrivateRoute exact path="/admin/webinars/:webinarId" component={WebinarPage} />

            <AdminPrivateRoute exact path="/admin/zoom/redirect" component={ZoomRedirectPage} />
            <AdminPrivateRoute exact path="/admin/zoom/auth" component={ZoomAuthPage} />
            <AdminPrivateRoute exact path="/admin/zoom/connect" component={ZoomConnectPage} />

            <AdminPrivateRoute exact path="/admin/bank-details" component={BankDetailsShowPage} />
            <AdminPrivateRoute exact path="/admin/bank-details/edit" component={BankDetailsEditPage} />

            <AdminPrivateRoute exact path="/admin/settings/plan" component={AdminSchoolPlanPage} />
            <AdminPrivateRoute path="/admin/landing-pages/:landingPageId" withoutAppLayout component={LandingPagePage} />
            <AdminPrivateRoute path="/admin/landing-pages" component={LandingPagesPage} />

            {/* Users */}
            <Route exact path="/register" component={RegisterPage} />
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/logout" component={LogoutPage} />
            <Route exact path="/forgot-password" component={ForgotPasswordPage} />
            <Route exact path="/reset-password/:token" component={ResetPasswordPage} />
            <Route exact path="/schools/:schoolId/courses/:courseId/purchase" component={CoursePurchasePage} />
            <Route exact path="/schools/:schoolId/plans/:planId/subscribe" component={PlanSubscribePage} />

            {/* TODO: AdminPrivateRoute though it works correctly, the name seems wrong for users */}
            <AdminPrivateRoute path="/profile/edit" withoutAppLayout component={ProfileEditPage} />
            <AdminPrivateRoute path="/profile/schools/new" withoutAppLayout component={CreateSchoolPage} />
            <AdminPrivateRoute path="/profile" withoutAppLayout component={ProfilePage} />

            {/* Students */}
            <Route exact path="/courses/enrolled" component={StudentCoursesIndexPage} />
            <Route exact path="/courses" component={StudentAllCoursesPage} />
            <Route exact path="/courses/:courseId/" component={StudentCoursesShowPage} />
            <Route exact path="/courses/:courseId/lectures/:lectureId" component={StudentLecturesShowPage} />

            {/* Leads */}
            <Route exact path="/p/:landingPageId" component={LeadLandingPage} />

            {/* Site */}
            <Route exact path="/privacy-policy" component={PrivacyPage} />
            <Route exact path="/cancellation-refund-policy" component={CancellationPage} />
            <Route exact path="/terms-and-conditions" component={TermsPage} />
            <Route exact path="/snf" component={SchoolNotFound} />
            <Route exact path="/" component={getHomePageComponent()} />

            <Route path="/" component={Error404Page} />
          </Switch>
        </Suspense>
      </Route>
    </div>
  )
}

export default view(Routes)
