import React, { useEffect, useState } from "react"
import { view } from "@risingstack/react-easy-state"
// import { TweenMax, Power3 } from "gsap"
import {
  PlayCircleOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons"
import { Row, Col } from "antd"

import PlansServices from "services/guest/plans.service"
import * as URLHelper from "helpers/url.helper"

import { Color } from "constants/theme"
import {
  GradientText,
  CustomButton,
  EmailText,
  StyledHomePage,
  StyledPricingCards,
  StyledTestimonialCards,
  InvitationButton,
} from "components/common/styled-components/StyledSiteHomePage"

const faq = [
  {
    question: "WILL COURSEMANGO INTEGRATE WITH MY OTHER SERVICES?",
    answer: [
      "Right now, CourseMango seamlessly integrates with these email providers: ActiveCampaign, AWeber, Campaign Monitor, Constant Contact, Convertkit, Drip, GetResponse, Infusionsoft, Mailchimp and Ontraport.",
      "And we integrate with these shopping cart and payment services: Stripe, Paypal and Infusionsoft",
    ],
  },
  {
    question: "DOES COURSEMANGO WORK WITH [WORDPRESS, SQUARESPACE, WIX]?",
    answer: [
      "I’ve got good news: no matter what website service you use, you can use CourseMango. Why? Because CourseMango handles the hosting of your course website. You do not need to install anything on your website.",
    ],
  },
  {
    question: "WHAT IF I DON’T HAVE MY OWN WEBSITE YET?",
    answer: [
      "When you get CourseMango, you get a free CUSTOM sub domain at CourseMango. yourawesomecourses.coursemango.com. So, if you want it to be your name, it would appear like dineshgadge.coursemango.com.",
      "The best part? When you later buy your own domain name, you can transfer everything from subdomain.coursemango.com to your new subdomain within a few minutes (our team will help you do it).",
    ],
  },
  {
    question: "HOW MANY COURSES CAN I CREATE ON COURSEMANGO?",
    answer: [
      "When you get our Standard plan, you can create UNLIMITED courses. So, whether you want to create 1 online course... or 100... there’s no limit on how many courses you can create.",
    ],
  },
]

const HomePage = () => {
  const currency = "inr"
  const [frequency, setFrequency] = useState("monthly")
  const [plans, setPlans] = useState(null)

  useEffect(() => {
    const pageState = {}

    const fetchPlans = async () => {
      await PlansServices.index({ pageState })

      const { plans } = pageState

      if (plans && plans.length > 0) {
        setPlans(plans)
      }
    }

    if (!pageState.plans) {
      fetchPlans()
    }
  }, [])

  const changeFrequency = (f) => {
    setFrequency(f)
  }

  const getPrice = (prices) => {
    if (!prices) return null

    const price = prices.find((p) => p.currency === currency)

    if (!price) return null

    return `₹ ${Math.round(price[`${frequency}_price`] || 0)}/-`
  }

  const handleSignup = () => {
    window.location = URLHelper.getSecureURL("/register")
  }

  const handleSignin = () => {
    window.location = URLHelper.getSecureURL("/login")
  }

  const renderPlans = () => {
    if (!plans) return null

    const paidPlans = plans.filter((p) => !p.free)

    console.log("renderPlans", paidPlans)

    if (paidPlans.length === 3) {
      return paidPlans.map((p, i) => {
        return (
          <div key={`plan-${i + 1}`}>
            <div>
              <h1 className="name">
                <strong>{p.name}</strong>
              </h1>
              <h3 className="frequency">{frequency} Package</h3>
              <h2 className="price">
                <GradientText>{getPrice(p.prices)}</GradientText>
              </h2>
              <div className="features">{renderFeatures(p.plan_features)}</div>
              <CustomButton primary onClick={handleSignup}>
                Buy Now
              </CustomButton>
            </div>
          </div>
        )
      })
    }
  }

  const renderFeatures = (planFeatures = []) => {
    // const formattedFeatures = planFeatures.map(({ feature, value, display }) => {
    //   if (!display) return null

    //   return {
    //     feature,
    //     value,
    //   }
    // })

    // console.log("formattedFeatures", formattedFeatures)

    return planFeatures.map(({ display, feature: { unit, display_name }, value }, index) => {
      if (unit === "none") {
        if (value) {
          return (
            <div key={index}>
              <CheckCircleOutlined style={{ color: Color.right }} /> {display_name}
            </div>
          )
        }
        return (
          <div key={index}>
            <CloseCircleOutlined style={{ color: Color.wrong }} /> {display_name}
          </div>
        )
      }
      if (unit === "number") {
        return (
          <div key={index}>
            <span style={{ textTransform: "uppercase" }}>{value}</span> {display_name}
          </div>
        )
      }

      if (unit === "percentage") {
        return (
          <div key={index}>
            <span style={{ textTransform: "uppercase" }}>{value}%</span> {display_name}
          </div>
        )
      }
      return null
    })
  }

  return (
    <StyledHomePage>
      <section className="section-1">
        <nav>
          <div className="logo">CourseMango</div>
          <div className="links">
            <ul>
              <li>
                <CustomButton onClick={handleSignin}>Signin</CustomButton>
              </li>
              <li>
                <CustomButton primary onClick={handleSignup}>
                  Signup Now
                </CustomButton>
              </li>
            </ul>
          </div>
        </nav>
        <div className="hero">
          <Row>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <div className="left-container">
                <h1>Build your own Online School</h1>
                <h3>
                  You can create your own online school and launch with onclick with a very minimal cost. Awesome course
                  creator and smoth enrollment feature can help you to make money without any worry.
                </h3>
                <div className="cta-buttons">
                  <CustomButton primary onClick={handleSignup}>
                    Signup Now
                  </CustomButton>
                  <CustomButton withIcon>
                    <PlayCircleOutlined /> Watch video
                  </CustomButton>
                </div>
              </div>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <div className="right-container">
                <div className="image-box">
                  <img src={process.env.PUBLIC_URL + "/images/ui-learning.svg"} alt="" />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <section className="section-2">
        <Row>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <div className="left-container">
              <div className="image-box">
                <img src={process.env.PUBLIC_URL + "/images/ui-prototype.svg"} alt="" />
              </div>
            </div>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <div className="right-container">
              <h1>Designed for teachers and students with better UI</h1>
              <h3>
                Finding a good online course creator is easy but with better UI and better performance is not easy
                because they are not well optimized for <strong>sales</strong> and <strong>teaching</strong>.
              </h3>
              <h3>
                <GradientText>CourseMango</GradientText>'s course creator is well optimized for both sales and teaching.
              </h3>
              <div className="cta-buttons">
                <CustomButton primary onClick={handleSignup}>
                  Signup Now
                </CustomButton>
              </div>
            </div>
          </Col>
        </Row>
      </section>
      <section className="section-3">
        <h1>
          Why to choose <GradientText>CourseMango</GradientText>
        </h1>
        <Row>
          <Col xs={{ span: 24 }} lg={{ span: 12 }} className="gradient-row left">
            <div className="left-container">
              <div className="image-box">
                <img src={process.env.PUBLIC_URL + "/images/ui-build.svg"} alt="" />
              </div>
            </div>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <div className="right-container">
              <h1>Easy-to-use course creator</h1>
              <h3>
                With our course creator you can create course in simple steps. Divide the course into sections and
                lectures.
              </h3>

              <div className="cta-buttons">
                <CustomButton primary onClick={handleSignup}>
                  Signup Now
                </CustomButton>
              </div>
            </div>
          </Col>
        </Row>
      </section>
      <section className="section-4">
        <Row>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <div className="left-container">
              <h1>Sell Different Versions of Your Course With Access-Tiers</h1>
              <h3>
                How would you like to increase your course sales by 25%? Well, if you don't offer a premium version of
                your course, you're leaving revenue on the table.
              </h3>
              <h3>
                That's why CourseMango allows you to create “access tiers.” So, instead of just selling one course at
                one price… you can create one course with multiple tiers and sell it at different prices.
              </h3>

              <div className="cta-buttons">
                <CustomButton primary onClick={handleSignup}>
                  Signup Now
                </CustomButton>
              </div>
            </div>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }} className="gradient-row right">
            <div className="right-container">
              <div className="image-box">
                <img src={process.env.PUBLIC_URL + "/images/ui-build.svg"} alt="" />
              </div>
            </div>
          </Col>
        </Row>
      </section>
      <section className="section-5">
        <Row>
          <Col xs={{ span: 24 }} lg={{ span: 12 }} className="gradient-row left">
            <div className="left-container">
              <div className="image-box">
                <img src={process.env.PUBLIC_URL + "/images/ui-build.svg"} alt="" />
              </div>
            </div>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <div className="right-container">
              <h1>Keep Your Students Happy (and Engaged) with Automatic Follow-Up Emails:</h1>
              <h3>
                When someone buys your course, the student experience BEGINS. And unless you stay in touch with your
                students, they may fall behind, lose motivation, or feel left alone.
              </h3>

              <div className="cta-buttons">
                <CustomButton primary onClick={handleSignup}>
                  Signup Now
                </CustomButton>
              </div>
            </div>
          </Col>
        </Row>
      </section>
      {plans && (
        <section className="section-6">
          <h1>Our Best Pricing</h1>
          <div className="frequency-switch">
            <CustomButton primary={frequency === "monthly"} onClick={() => changeFrequency("monthly")}>
              Monthly
            </CustomButton>
            <CustomButton primary={frequency === "annual"} onClick={() => changeFrequency("annual")}>
              Annual
            </CustomButton>
          </div>
          <StyledPricingCards>{renderPlans()}</StyledPricingCards>
        </section>
      )}
      <section className="section-7">
        <h1>Our Happy Clients</h1>

        <StyledTestimonialCards>
          <div>
            <p className="content">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua.
            </p>
            <div className="client">
              <h3 className="name">John Walker</h3>
              <h4 className="designation">Teacher</h4>
            </div>
          </div>
          <div>
            <p className="content">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua.
            </p>
            <div className="client">
              <h3 className="name">Joe Rick</h3>
              <h4 className="designation">Businessman</h4>
            </div>
          </div>
          <div>
            <p className="content">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua.
            </p>
            <div className="client">
              <h3 className="name">Alice Jackson</h3>
              <h4 className="designation">Software Developer</h4>
            </div>
          </div>
        </StyledTestimonialCards>
      </section>
      <section className="section-8">
        <h1>
          Have any question about <GradientText>CourseMango</GradientText>?
        </h1>
        <h3>
          Contact us anytime: <EmailText>contact@coursemango.com</EmailText>
        </h3>
        <div className="faq-box">
          {faq.map((f, index) => (
            <label key={index}>
              <input type="checkbox" />
              <h3>
                <span>{f.question}</span>
                <span>
                  <PlusCircleOutlined />
                  <MinusCircleOutlined />
                </span>
              </h3>
              <div>
                {f.answer.map((a, index) => (
                  <p key={index}>{a}</p>
                ))}
              </div>
            </label>
          ))}
        </div>
      </section>
      <section className="section-9">
        <h3>
          If you want to be first to experience it, enter your email in the form below, and click "Get Your Invitation"
        </h3>
        <InvitationButton>Get Your Invitation</InvitationButton>
      </section>
      <footer>
        <div>
          Copyright &copy; <GradientText>CourseMango</GradientText>
        </div>
        <div>
          Made with{" "}
          <span role="img" aria-label="heart">
            ❤️
          </span>{" "}
          in <strong>India</strong>
        </div>
      </footer>
    </StyledHomePage>
  )
}

export default view(HomePage)
