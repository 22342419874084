import React, { useState, useEffect } from "react"
import { view } from "@risingstack/react-easy-state"
import styled from "styled-components"
import { useHistory, useParams } from "react-router-dom"

import { Row, Col, message } from "antd"

import ResetPasswordForm from "components/common/ResetPasswordForm"

import { Color } from "constants/theme"

import PasswordsService from "services/users/passwords.service"
import ContentLoading from "components/common/ContentLoading"

const StyledPage = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: ${Color.pageColor};
`

const StyledPageContent = styled.div`
  position: relative;
`

const StyledPageContentCoverBackground = styled.div`
  width: 100%;
  position: absolute;
  overflow: hidden;
  height: 100vh;
  & > div {
    min-width: calc(100vw + 100px);
    height: 400px;
    border-radius: 0px 0px 0px 20px;
    background-image: linear-gradient(20deg, ${Color.brandColor} 7%, #2dbde4 93%);
    position: absolute;
    -webkit-tranform-origin: bottom left;
    tranform-origin: bottom left;
    -webkit-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    transform: rotate(-10deg);
    left: -50px;
    top: -120px;
  }
`

const StyledLink = styled.div`
  & > button {
    padding: 0px;
    color: white;
    &:hover {
      color: white;
    }
  }
`

const StyledMainContent = styled.div`
  padding: 20px 100px;
  @media only screen and (max-width: 576px) {
    padding: 20px;
  }
  & > div.form-row {
    margin-top: 50px;
  }
`

const StyledFormRowLeft = styled.div`
  & > * {
    color: white;
  }
  & > h1 {
    font-size: 2rem;
    font-weight: bold;
    margin: 0px;
  }
  & > p {
    font-size: 1.2rem;
  }
`

const StyledFormRowRight = styled.div`
  width: 555px;
  @media only screen and (max-width: 576px) {
    width: 100%;
  }
`

const ResetPasswordPage = () => {
  const history = useHistory()
  const { token } = useParams()
  const [submitting, setSubmitting] = useState(false)
  const [tokenValid, setTokenValid] = useState(false)

  useEffect(() => {
    const validateToken = async () => {
      const pageState = {}

      await PasswordsService.edit({ pageState, token })

      const { serverStatus } = pageState

      if (!serverStatus || serverStatus.status !== 200) {
        message.error("Password reset token is expired or invalid")
        history.push("/forgot-password")
        return
      }

      setTokenValid(true)
    }

    validateToken()
  }, [token, history])

  const handleSubmit = async (values) => {
    const { password, password_confirmation } = values

    message.destroy()

    if (password !== password_confirmation) {
      message.error("New password and new password confirmation mismatch.")
      return
    }

    const pageState = {}

    setSubmitting(true)
    await PasswordsService.update({ pageState, token, values: { password } })
    setSubmitting(false)

    const { serverStatus } = pageState

    if (!serverStatus || serverStatus.status !== 200) {
      message.error("Failed to update password")
      history.push("/forgot-password")
      return
    }

    message.success("Password has been updated, login to continue.")
    history.push("/login")
    return
  }

  if (!token) {
    message.warning("Token is invalid")
    return
  }

  return (
    <StyledPage>
      <StyledPageContent>
        <StyledPageContentCoverBackground>
          <div></div>
        </StyledPageContentCoverBackground>
        <StyledMainContent>
          <Row>
            <StyledLink>
              {/* <Button type="link" onClick={() => history.push("/login")}>
                <ArrowLeftOutlined /> Go to Login
              </Button> */}
            </StyledLink>
          </Row>
          <div className="form-row">
            <Row type="flex" justify="space-between" align="top">
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <StyledFormRowLeft>
                  <h1>CourseMango</h1>
                  <p>
                    Spread Your Knowledge <br /> Create and Sell Online Courses
                  </p>
                </StyledFormRowLeft>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <StyledFormRowRight>
                  {tokenValid ? (
                    <ResetPasswordForm onSubmit={handleSubmit} submitting={submitting} />
                  ) : (
                    <ContentLoading />
                  )}
                </StyledFormRowRight>
              </Col>
            </Row>
          </div>
        </StyledMainContent>
      </StyledPageContent>
    </StyledPage>
  )
}

export default view(ResetPasswordPage)
