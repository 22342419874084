import styled from "styled-components"

import { Height, BoxShadow } from "constants/theme"

const StyledCard = styled.div`
  position: relative;
  width: 100%;
  height: ${Height.card}px;
  box-shadow: ${BoxShadow.card.normal};
  border-radius: 5px;
  overflow: hidden;
  background: white;
  transition: all 0.5s;
  &:hover {
    cursor: pointer;
    box-shadow: ${BoxShadow.card.hover};
  }
  & > div.course-details {
    position: absolute;
    width: 100%;
    top: 0px;
    height: inherit;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: linear-gradient(180deg, transparent 200px, #00000094);
  }
`

export default StyledCard
