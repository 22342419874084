import styled from "styled-components"

import { Color } from "constants/theme"

const StyledCardBackground = styled.div`
  width: 100%;
  // height: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${Color.brandColor};
  background-image: url(${(props) => props.url});
  background-size: cover;
  & > span {
    font-size: 10em;
    color: white;
    // margin-top: -100px;
  }
`

export default StyledCardBackground
